(function() {
    'use strict';

    angular.module('pt.directives')
        .directive('tdSectionHeader', directiveFn);

    directiveFn.$inject = [];
    function directiveFn() {
        var directive = {
            restrict:'E',
            scope: {
                headerText: '@'  
            },
            replace:true,
            transclude:true,
            template: '<div class="underline-grey-small">' +
                           '<div class="h2" ng-bind="headerText"></div>' +
                      '</div>',
            compile:compileFn
        };
        return directive;

        function compileFn(tEle, tAttrs, transclude) {
            return postLinkFn;

            function postLinkFn(scope, elem, attr) {
            }
        }
    }

})();
