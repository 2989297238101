'use strict';

/* Controllers */
(function () {
    angular.module('pt.controllers')
        .controller('messagingCentreCtrl',
            ['$rootScope', '$scope', '$sce', '$http', 'flash', 'resourceFactory', '$stateParams', '$state', '$q', '$interval', 'usSpinnerService', '$window', '$timeout', 'shell', '$location', '$analytics',
            function ($rootScope, $scope, $sce, $http, flash, resourceFactory, $stateParams, $state, $q, $interval, usSpinnerService, $window, $timeout, shell, $location, $analytics) {
            var messagesApi = resourceFactory.create('messages');
            var profileApi = resourceFactory.create('profile');

            $scope.model = {};
            $scope.showContent = false;
            $scope.isContactsLoaded = false;
            $scope.isMessagesLoaded = false;
            $scope.model.tenantUnreadCount = "";
            $scope.model.ownerUnreadCount = "";
            $scope.model.totalUnreadCount = "";
            $scope.isOwner = false;
            $scope.isTenant = false;
            $scope.showOwner = $stateParams.profileType === 'owner' ? true : false;
            $scope.showTenant = $stateParams.profileType === 'owner' ? false : true;
            $scope.progressCount = 0;
            $scope.isOwnerTabActive = false;
            $scope.isMessagesLoading = false;
            
            $scope.ownerStatus = {
                isThreadsOpen: false
            };

            $scope.tenancyStatus = {
                isThreadsOpen: false
            };

            $scope.contactsSpinnerStatus = false;
            $scope.inboxSpinnerStatus = false;
            $scope.threadsSpinnerStatus = false;
            $scope.converseSpinnerStatus = false;
            $scope.isSending = false;
            $scope.doBlur = false;

            $scope.pageSpinnerStatus = function () {
                return $scope.contactsSpinnerStatus || $scope.inboxSpinnerStatus;
            };

            $('#contactListView').click('li', function () {
                $('#contactListView').collapse('hide');
            });
            
            $scope.tinymceModel = {};

            $scope.popover = {
                scopeHelper: $scope,
                options: {
                    html: true,
                    container: '#cancelButton',
                    content: '<div>Are you sure you want to cancel and discard this message?</div>' +
                                    '<div class="spacer10"></div>' +
                                '<div class="clearfix"><div class="pull-right"><button type="button" class="btn secondary" ng-click="closePopover($event)"><b>No</b></button>&nbsp;&nbsp;&nbsp;&nbsp;<button type="button" class="btn primary" ng-click="confirmCancel()">Yes</button></div></div>',
                    placement: 'top'
                },
            };

            $scope.closePopover = function (event) {
                $scope.popover.options.dismissPopover();
                event.stopPropagation();
            };

            $scope.tinymceOptions = {
                toolbar: "undo redo | bold italic | alignleft alignjustify | bullist numlist | link unlink",
                plugins: "link",
                target_list: false,
                link_title: false,
                extended_valid_elements: 'a[href|target=_blank]',
                relative_urls: false,
                menubar: false,
                statusbar: false,
                init_instance_callback: function (ed) {
                    ed.on('click', function (e) {
                        ed.focus();
                    });
                }
            };

          

            /////////// Inbox functions

            $scope.retrieveInboxThreads = function (type) {
                $scope.model.threads = [];
                $scope.isMessagesLoaded = false;
                $scope.inboxSpinnerStatus = true;
                messagesApi.getThreads({ contactType: type }, function success(data) {
                    $scope.inboxSpinnerStatus = false;
                    $scope.model.threads = data;
                    $scope.isMessagesLoaded = true;
                });
            };

            $scope.getContacts = function (type) {
                $scope.isContactsLoaded = false;
                $scope.contactsSpinnerStatus = true;
                $scope.model.contacts = [];
                
                messagesApi.getContacts({ contactType: type }, function success(data) {
                    $scope.contactsSpinnerStatus = false;
                    $scope.model.contacts = data;
                    $scope.isContactsLoaded = true;
                }); 
            };

                $scope.setEntity = function (profiles) {
                    if (profiles) {
                        for (var i = 0; i < profiles.length; i++) {
                            if (profiles[i].ProfileType === 'Owner') {
                                $scope.isOwner = true;
                            }

                            if (profiles[i].ProfileType === 'Tenancy') {
                                $scope.isTenant = true;
                            }
                        }
                    }
                };

            $scope.canChangeTab = function () {
                if ($scope.contactsSpinnerStatus || $scope.inboxSpinnerStatus) {
                    return false;
                }
                return true;
            };

            $scope.loadProfiles = function () {
                profileApi.profiles({ profileType: '' }, function (data) {
                    $scope.setEntity(data);
                    return data;
                });
            };

            if ($rootScope.user) {
                $scope.loadProfiles();
            }

            $scope.getUnreadCounts = function () {

                $scope.model.totalUnreadCount = 0;
                messagesApi.getUnreadMessageCount({ contactType: 'Owner' }, function success(data) {
                    if (data.UnreadCount !== 0) {
                        $scope.model.ownerUnreadCount = "(" + data.UnreadCount + ")";
                        $scope.model.totalUnreadCount += data.UnreadCount;
                    }
                    else {
                        $scope.model.ownerUnreadCount = "";
                    }
                });

                messagesApi.getUnreadMessageCount({ contactType: 'Tenancy' }, function success(data) {
                    if (data.UnreadCount !== 0) {
                        $scope.model.tenantUnreadCount = "(" + data.UnreadCount + ")";
                        $scope.model.totalUnreadCount += data.UnreadCount;
                    }
                    else {
                        $scope.model.tenantUnreadCount = "";
                    }
                });
            };

            $scope.$on('ReloadUnreadMessages', $scope.getUnreadCounts);

            var timeout;
            var oldSearchText;
             
            $scope.$watch('model.searchText', function (val) {
                if (val) {
                    oldSearchText = val;
                }
                delaySearch(val, $stateParams.profileType === 'owner' ? 'Owner' : 'Tenancy');
            });

            function searchMessageList(search, type) {
                $scope.inboxSpinnerStatus = true;
                messagesApi.searchThreads({ searchTerm: search, contactType: type }, function success(data) {
                    $scope.inboxSpinnerStatus = false;
                    $scope.model.threads = data.ThreadList;
                });
            }

            function delaySearch(searchVal, type) {
                var tempVal = searchVal;
                if (timeout) $timeout.cancel(timeout);
                timeout = $timeout(function () {
                    var search = tempVal;
                    if (search || oldSearchText) {
                        searchMessageList(search, type);
                    }
                }, 300);
            }

            $scope.selectedItem = function (type, item) {
                if (type === 'thread') {
                    $analytics.eventTrack(toTitleCase($stateParams.profileType), { category: 'Messaging', label: 'View Messages' });
                    $state.go('shell.messageThreadView', {
                        profileType: $stateParams.profileType,
                        companyReference: item.OriginalFromAddress.CompanyReference,
                        threadId: item.ThreadID
                    });
                } else {
                    $analytics.eventTrack(toTitleCase($stateParams.profileType), { category: 'Messaging', label: 'View Contacts' });
                    $state.go('shell.messageThreadView', {
                        profileType: $stateParams.profileType,
                        companyReference: item.CompanyReference,
                        participantsHash: item.ParticipantsHash
                    });
                }
            };

            $scope.gotoInbox = function () {
                var profileType = $stateParams.profileType === 'owner' ? 'Owner' : 'Tenancy';
                $rootScope.ownerTheme = $stateParams.profileType === 'owner' ? true : false;
                $scope.getContacts(profileType);
                $scope.retrieveInboxThreads(profileType);
                oldSearchText = undefined;
                $scope.model.searchText = undefined;
                if (profileType === 'Owner') {
                    $scope.showOwner = true;
                    $scope.showTenant = false;
                    $scope.isOwnerTabActive = true;
                }
                else {
                    $scope.showOwner = false;
                    $scope.showTenant = true;
                    $scope.isOwnerTabActive = false;
                }
            };

            $scope.gotoInbox();

            $scope.getAttachmentUrl = function (rawUrl) {
                return $sce.trustAsResourceUrl(rawUrl);
            };

            /////////// Compose new message functions.
            $scope.composeMessage = function () {
                $analytics.eventTrack(toTitleCase($stateParams.profileType), { category: 'Messaging', label: 'Compose Message' });

                if ($stateParams.profileType === 'owner') {
                    $state.go('shell.messageCompose', {
                        profileType: 'owner'
                    });
                } else {
                    $state.go('shell.messageCompose', {
                        profileType: 'tenant'
                    });
                }
            };

            function toTitleCase(str) {
                if (!str) return '';
                return str.replace(/\w\S*/g, function (txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                });
            }
            
            $scope.$on('UserLoggedIn', $scope.loadProfiles);

            $scope.$on('LoadMessages', $scope.gotoInbox);
        }]);
})();
