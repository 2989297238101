"use strict";
(function(){
  angular.module('pt.directives')
    .directive("tdClickAction", ["$log", "$timeout", function($log, $timeout){
      var dir = {
        restrict:"A",
        scope:{
          tdClickAction:"&"
        },
        link:function($scope, el, attrs){
          var timer;
          angular.element(el).on('click', function(){
            timer = $timeout(function(){
              $scope.$eval($scope.tdClickAction);
              $scope.$apply();
            }, 300);
          });

          $scope.$on('$destroy', function(){
            if(timer)
              $timeout.cancel(timer);
          });

        }
      };
      return dir;
    }]);
})();