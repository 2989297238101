'use strict';

/* Controllers */
(function () {
    angular.module('pt.controllers')
        .controller('homeCtrl', ['$scope', '$rootScope', '$http', 'resourceFactory', 'usSpinnerService', '$state', '$window', '$anchorScroll', '$timeout', function ($scope, $rootScope, $http, resourceFactory, usSpinnerService, $state, $window, $anchorScroll, $timeout) {
            /* 
            -------------------------------------------------------------------------------------------------------------------------
                COMMON
            -------------------------------------------------------------------------------------------------------------------------
            */

            var dashboardapi = resourceFactory.create('dashboard');
            var companyFeatureAPI = resourceFactory.create('companyFeature');
            $scope.oneAtATime = true;
            $scope.spinneractive = false;
            $scope.username = $rootScope.user.Name;
            $scope.model = {};
            $scope.propertyStatus = {
                openPropertyDetails: true,
                openManagementDetails: false
            };

            $scope.tenancyStatus = {
                openPropertyDetails: true,
                openManagementDetails: false
            };

            $scope.selectedTab = '';
            $scope.multipleTabs = false;
            $scope.propertiesSpinnerStatus = false;
            $scope.tenacySpinnerStatus = false;
            $scope.companyFeatures = [];

            var popoverTemplate = '<div ng-show="!tenancyDetails.HasPartialDayCredit && tenancyDetails.HasPartialPeriodCredit">You have paid up to the&nbsp;{{tenancyDetails.EffectivePaidTo}}.</div>' + '<div ng-show="tenancyDetails.HasPartialDayCredit">You have paid rent up to {{tenancyDetails.EffectivePaidTo}}, as well as an extra {{tenancyDetails.PartialDayCredit}} that does not cover a full day\'s rent.</div>';

            $scope.loadDashBoardData = function () {
                $scope.dashboardSpinnerStatus = true;

                dashboardapi.query({}, function (data) {
                    $scope.selectedProperty = null;
                    $scope.dashBoard = data;
                    $scope.properties = data[0].Properties;
                    $scope.tenancies = data[0].Tenancies;
                    $scope.propertySnapshot = data[0].PropertySnapshot;
                    $scope.model.inspectionDetails = data[0].Inspections.length > 0 ? data[0].Inspections[0] : null;
                    if ($scope.model.inspectionDetails) {
                        $scope.model.displayInspectionDate = $scope.model.inspectionDetails.ProposalStatus && $scope.model.inspectionDetails.ProposalStatus === 'TenantProposed' ? $scope.model.inspectionDetails.ClientInspectionDate : $scope.model.inspectionDetails.InspectionDate;
                        $scope.model.displayInspectionTime = $scope.model.inspectionDetails.ProposalStatus && $scope.model.inspectionDetails.ProposalStatus === 'TenantProposed' ? $scope.model.inspectionDetails.ClientInspectionTime : $scope.model.inspectionDetails.InspectionTime;
                    }

                    if ($scope.properties.length > 0) {
                        $scope.isOwner = true;
                    }

                    if ($scope.tenancies.length > 0) {
                        $scope.isTenancy = true;
                    }

                    $scope.propertiesText = $scope.propertySnapshot.Properties === 1 ? 'Property' : 'Properties';
                    $scope.tenanciesVacatingText = $scope.propertySnapshot.TenanciesVacating === 1 ? 'Tenancy Vacating' : 'Tenancies Vacating';
                    $scope.tenancyInArrearsText = $scope.propertySnapshot.TenanciesInArrears === 1 ? 'Tenancy in Arrears' : 'Tenancies in Arrears';
                    $scope.propertyVacanciesText = $scope.propertySnapshot.ProperyVacancies === 1 ? 'Property Vacancy' : 'Property Vacancies';

                    $scope.multipleTabs = $scope.isOwner && $scope.isTenancy;

                    $scope.dashboardSpinnerStatus = false;

                    if ($scope.isOwner) {
                        $scope.propertiesTabSelected();
                    } else if ($scope.isTenancy) {
                        $scope.tenancyTabSelected();
                    }

                    $scope.loadCompanyFeatures();

                }, function (error) {
                    $scope.dashboardSpinnerStatus = false;
                });
            };



            /* 
            -------------------------------------------------------------------------------------------------------------------------
                PROPERTIES TAB
            -------------------------------------------------------------------------------------------------------------------------
            */

            var propertiesapi = resourceFactory.create('properties');

            $scope.propertySelected = function (property) {
                $scope.selectedProperty = property;
            };

            $scope.hasMultipleProperties = function () {
                return ($scope.properties && $scope.properties.length > 1);
            };

            $scope.sendMessageOwner = function (managementId) {
                $state.go('shell.messageCompose', {
                    profileType: 'owner',
                    managementId: managementId
                });
                $anchorScroll.yOffset = 0;
                $anchorScroll();
            };

            $scope.loadProperty = function (property) {
                $scope.propertyDetails = null;

                if (!property) {
                    return;
                }

                $scope.propertiesSpinnerStatus = true;

                var params = {
                    CompanyReference: property.CompanyReference,
                    ContactReference: property.ContactReference,
                    PropertyReference: property.PropertyReference
                };
                propertiesapi.get(params, function (data) {
                    $scope.propertiesSpinnerStatus = false;

                    $scope.propertyDetails = data;
                }, function (error) {
                    $scope.propertiesSpinnerStatus = false;
                });
            };

            $scope.$watch('selectedProperty', function (val) {
                $scope.loadProperty(val);
            });

            $scope.propertiesTabSelected = function () {
                if (!$scope.selectedProperty) {
                    $scope.selectedProperty = $scope.properties[0];
                }

                $scope.selectedTab = 'owner';
                $rootScope.ownerTheme = true;
            };

            /*
            ------------------------------------------------------------------------------------------------------------------------
                Charts
            ------------------------------------------------------------------------------------------------------------------------
            */

            var today = new Date();
            $scope.fromDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate() + 1);
            $scope.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            $scope.maxFromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            $scope.maxToDate = $scope.maxFromDate;

            $scope.chart = {
                labels: [],
                series: ['Income', 'Expenses'],
                data: []
            };

            $scope.$on('create', function() {
                //$timeout(updateChart, 1000);
            });

            var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                              "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

            function monthDiff(fromDate, toDate) {
                var months;
                months = (toDate.getFullYear() - fromDate.getFullYear()) * 12;
                months -= fromDate.getMonth();
                months += toDate.getMonth();
                return months;
            }

            $scope.loadIncomeExpenseGraph = function () {
                if ($scope.fromDate && $scope.toDate) {
                    if ($scope.fromDate && $scope.toDate) {
                        $scope.graphSpinnerStatus = true;
                        dashboardapi.getIncomeExpenseGraph({ startDate: $scope.fromDate.toJSON(), endDate: $scope.toDate.toJSON() }, function (data) {
                            $scope.model.graphData = data;
                            $scope.allData = [];
                            var graphData = $scope.model.graphData;

                            for (var i = 0; i < graphData.length; i++) {
                                var chart = { data: [], labels: [], series: ['Income', 'Expenses'] };
                                chart.data = setData(graphData[i].GraphData);
                                chart.labels = $scope.chart.labels;
                                chart.options = setOptions(graphData[i].CurrencyCode);
                                chart.currencyDisplay = graphData[i].CurrencyCode;
                                $scope.allData.push(chart);
                            }

                            $scope.graphSpinnerStatus = false;
                        }, function(error) {
                            $scope.graphSpinnerStatus = false;
                        });
                    }
                } else {
                    return;
                }
                
                
            };

            function setOptions(currency) {
                return {
                    multiTooltipTemplate: function (label) { return label.datasetLabel + ': ' + currencySymbols[currency] + label.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); },
                    scaleLabel: function (label) { return ' ' + currencySymbols[currency] + label.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); }
                };
            }
            
            function setData(graphData) {
                var totalMonths = monthDiff($scope.fromDate, $scope.toDate);
                var localIncome = [];
                var localExpense = [];
                var localLabel = [];
                var data = [];
                var labels = [];
                $scope.chart.labels = [];
                var localDate = new Date();
                for (var i = 0; i <= totalMonths; i++) {
                    localIncome.push(0);
                    localExpense.push(0);
                    if (i === 0) {
                        localDate = new Date($scope.fromDate.getFullYear(), $scope.fromDate.getMonth(), $scope.fromDate.getDate());
                    } else if (i === totalMonths) {
                        localDate = new Date($scope.toDate.getFullYear(), $scope.toDate.getMonth(), $scope.toDate.getDate());
                    } else {
                        localDate = new Date(localDate.getFullYear(), localDate.getMonth() + 1, 1);
                    }

                    localLabel.push({ month: monthNames[localDate.getMonth()], year: localDate.getFullYear() });
                    labels.push(moment(new Date(localDate.toDateString())).format('MMM YYYY'));
                }

                for (var j = 0; j < localLabel.length; j++) {
                    for (var k = 0; k < graphData.length; k++) {
                        if (localLabel[j].month === monthNames[graphData[k].Month.getMonth()] && localLabel[j].year === graphData[k].Month.getFullYear()) {
                            localIncome[j] = graphData[k].Income;
                            localExpense[j] = graphData[k].Expense;
                        }
                    }
                }

                $scope.chart.labels = labels;
                data.push(localIncome);
                data.push(localExpense);

                return data;
            }

            var currencySymbols = {
                'USD': '$', // US Dollar
                'AUD': '$', // Australian Dollor
                'NZD': '$', // New Zealand Dollor
                'EUR': '€', // Euro
                'CRC': '₡', // Costa Rican Colón
                'GBP': '£', // British Pound Sterling
                'ILS': '₪', // Israeli New Sheqel
                'INR': '₹', // Indian Rupee
                'JPY': '¥', // Japanese Yen
                'KRW': '₩', // South Korean Won
                'NGN': '₦', // Nigerian Naira
                'PHP': '₱', // Philippine Peso
                'PLN': 'zł', // Polish Zloty
                'PYG': '₲', // Paraguayan Guarani
                'THB': '฿', // Thai Baht
                'UAH': '₴', // Ukrainian Hryvnia
                'VND': '₫', // Vietnamese Dong
            };

            var fromTimeout,
                toTimeout,
                oldFromDate,
                oldToDate;

            $scope.$watch('fromDate', function (val) {
                if (val === undefined) {
                    $scope.showFromDateText = true;
                } else {
                    $scope.showFromDateText = false;
                }

                if (val) {
                    oldFromDate = val;
                }
                delayFromInput(val);
            });

            $scope.$watch('toDate', function (val) {
                if (val === undefined) {
                    $scope.showToDateText = true;
                } else {
                    $scope.showToDateText = false;
                }

                if (val) {
                    oldToDate = val;
                }
                delayToInput(val);
            });

            function delayFromInput(dateVal) {
                var tempVal = dateVal;
                if (fromTimeout) $timeout.cancel(fromTimeout);
                fromTimeout = $timeout(function () {
                    var date = tempVal;
                    if (date || oldFromDate) {
                        $scope.loadIncomeExpenseGraph();
                    }
                }, 3000);
            }

            function delayToInput(dateVal) {
                var tempVal = dateVal;
                if (toTimeout) $timeout.cancel(toTimeout);
                toTimeout = $timeout(function () {
                    var date = tempVal;
                    if (date || oldToDate) {
                        $scope.loadIncomeExpenseGraph();
                    }
                }, 3000);
            }


            /* 
            -------------------------------------------------------------------------------------------------------------------------
                TENANCY TAB
            -------------------------------------------------------------------------------------------------------------------------
            */

            var tenancyapi = resourceFactory.create('tenancies');

            $scope.tenancySelected = function (tenancy) {
                $scope.selectedTenancy = tenancy;
            };

            $scope.hasMultipleTenancies = function () {
                return ($scope.tenancies && $scope.tenancies.length > 1);
            };

            $scope.sendMessageTenant = function (tenancyId) {
                $state.go('shell.messageCompose', {
                    profileType: 'tenant',
                    tenancyId: tenancyId
                });
                $anchorScroll.yOffset = 0;
                $anchorScroll();
            };

            $scope.loadTenancy = function (tenancy) {
                $scope.tenancyDetails = null;

                if (!tenancy) {
                    return;
                }

                $scope.tenancySpinnerStatus = true;

                var params = {
                    CompanyReference: tenancy.CompanyReference,
                    ContactReference: tenancy.ContactReference,
                    TenancyReference: tenancy.TenancyReference
                };

                tenancyapi.get(params, function (data) {
                    $scope.tenancySpinnerStatus = false;
                    $scope.tenancyDetails = data;
                }, function (error) {
                    $scope.tenancySpinnerStatus = false;
                });
            };

            $scope.popover = {
                scopeHelper: $scope,
                options: {
                    html: true,
                    container: '#tenancyPopup',
                    content: popoverTemplate,
                    placement: 'top'
                }
            };

            $scope.popoverMobile = {
                scopeHelper: $scope,
                options: {
                    html: true,
                    container: '#tenancyPopupMobile',
                    content: popoverTemplate,
                    placement: 'top'
                }
            };

            $scope.$watch('selectedTenancy', function (val) {
                $scope.loadTenancy(val);
            });

            $scope.tenancyTabSelected = function () {
                if (!$scope.selectedTenancy) {
                    $scope.selectedTenancy = $scope.tenancies[0];
                }

                $scope.selectedTab = 'tenancy';
                $rootScope.ownerTheme = false;
            };

            /*
            ------------------------------------------------------------------------------------------------------------------------
                Company Features
            ------------------------------------------------------------------------------------------------------------------------
            */

            $scope.loadCompanyFeatures = function () {

                var params = {
                    CompanyReference: $scope.isTenancy ? $scope.tenancy.CompanyReference : $scope.properties[0].CompanyReference,
                    ContactReference: $scope.isTenancy ? $scope.tenancy.ContactReference : $scope.properties[0].ContactReference
                };

                companyFeatureAPI.get(params, function (data) {
                    $scope.companyFeatures = data;
                    $rootScope.companyFeatures = data;
                });

            };

            /* 
            -------------------------------------------------------------------------------------------------------------------------
                COMMON
            -------------------------------------------------------------------------------------------------------------------------
            */

            if ($rootScope.user) {
                $scope.loadDashBoardData();
            }

            $rootScope.$on('UserLoggedIn', $scope.loadDashBoardData);


            /*
            ------------------------------------------------------------------------------------------------------------------------
                Inspections
            ------------------------------------------------------------------------------------------------------------------------
            */

            $scope.getClientTimeString = function () {
                if ($scope.model.inspectionDetails.InspectionTime) {
                    var time = moment($scope.model.displayInspectionTime);
                    var starTime = time.format("h:mm");
                    var startAmPm = time.format("A") === 'AM' ? 'a.m' : 'p.m';

                    var endTime = time.add($scope.model.inspectionDetails.Duration, 'minutes').format("h:mm");
                    var endAmPm = time.format("A") === 'AM' ? 'a.m' : 'p.m';
                    return starTime + ' ' + startAmPm + ' - ' + endTime + ' ' + endAmPm;
                }

               return '??:??';
            };

            $scope.$watch('model.displayInspectionTime', function (val) {
                if(val){
                    $scope.displayTime = $scope.getClientTimeString();
                }
            });
    }]);
})();
