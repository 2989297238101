(function () {
    'use strict';


    angular.module('pt.controllers')
        .controller('maintenanceRequestDetailsCtrl', controllerFn);

    controllerFn.$inject = ['$scope', 'resourceFactory', '$state', 'shell', '$stateParams', '$rootScope', 'enums', '$interval'];
    function controllerFn($scope, resourceFactory, $state, shell, $stateParams, $rootScope, enums, $interval) {
        var mrApi = resourceFactory.create('viewMaintenanceRequest');
        var contactApi = resourceFactory.create('viewContact');
        var messagesApi = resourceFactory.create('messages');

        $scope.vm = {
            files: [],
            updMRStatus: false
        };

        function isMessagesSwitchedOff() {
            messagesApi.isMessagesSwitchedOff({}, function (data) {
                $scope.isMessagesSwitchedOff = data.IsMessagesSwitchedOff;
            });
        }

        isMessagesSwitchedOff();

        $scope.profileType = $stateParams.profileType;
        var ownerApprovalenumList = angular.copy(enums.MaintenanceOwnerApprovalOption.displayEnumList);
        var quoteApprovalList = angular.copy(enums.MaintenanceOwnerApprovalOption.displayEnumList);
        function getContact() {
            var propData = {
                CompanyReference: $stateParams.companyReference,
                MaintenanceRequestReference: $stateParams.maintenanceRequestId
            };

            var getOwnerContact = contactApi.getOwnerContact(propData).$promise;
            getOwnerContact.then(function (data) {
                loadMaintenanceRequestDetail(data.ContactReference);
            });
        }

        var statusList = $scope.profileType === 'tenant' ?

            [{ color: 'blue', status: 'under review', isEmpty: false, isLastItem: false },
            { color: 'orange', status: 'in progress', isEmpty: true, isLastItem: false },
            { color: 'green', status: 'completed', isEmpty: true, isLastItem: true }]

            :

            [{ color: 'blue', status: 'under review', isEmpty: false, isLastItem: false },
            { color: 'orange', status: 'quote request', isEmpty: true, isLastItem: false },
            { color: 'dark blue', status: 'work order', isEmpty: true, isLastItem: false },
            { color: 'yellow', status: 'invoiced', isEmpty: true, isLastItem: false },
            { color: 'green', status: 'completed', isEmpty: true, isLastItem: true }];

        
        
        function setOptionLists() {
            $scope.optionList = _.each(ownerApprovalenumList, function (option) {
                option.isSelected = option.id === 1;
            });
            
            $scope.jobsOptionList = [];
            _.each(quoteApprovalList, function (option) {
                if (option.id !== 1) {
                    if (option.id === 2) {
                        option.optionText = 'I will attend to this job myself';
                    }

                    if (option.id === 3) {
                        option.optionText = 'I do not wish to proceed with any of these quotes';
                    }

                    $scope.jobsOptionList.push(option);
                }
            });

            $scope.jobsOptionList[0].isSelected = true;
        }

        $scope.isImage = function (fileName) {
            return /\.(svg|img|jpg|jpeg|png)$/gi.test(fileName);
        };

        function init() {
            setOptionLists();
            if (!$stateParams.contactReference) {
                $scope.profiles = _.filter($rootScope.user.Profiles, function (pr) {
                    return /owner/gi.test(pr.ProfileType);
                });

                if ($scope.profiles.length > 1) {
                    getContact();
                } else {
                    loadMaintenanceRequestDetail($scope.profiles[0].ContactReference);
                }

            } else {
                loadMaintenanceRequestDetail($stateParams.contactReference);
            }
        }

        init();


        function loadMaintenanceRequestDetail(contactId) {
            var propFullData = {
                CompanyReference: $stateParams.companyReference,
                ContactReference: contactId,
                MaintenanceRequestReference: $stateParams.maintenanceRequestId
            };

            $scope.maintenanceRequest = undefined;
            var viewMr = mrApi.getMaintenanceRequest(propFullData).$promise;
            viewMr.then(function (data) {
                $scope.vm.files = [];
                $scope.maintenanceRequest = data;
                setJobModel();
            });
        }

        function setJobModel() {
            if ($scope.profileType === 'owner') {
                setOwnerJobModel();
            } else {
                setTenantJobModel();
            }
        }

        function setTenantJobModel() {
            if ($scope.maintenanceRequest && $scope.maintenanceRequest.Jobs.length > 0) {
                for (var j = 0; j < $scope.maintenanceRequest.Jobs.length; j++) {
                    $scope.maintenanceRequest.Jobs[j].files = [];
                    $scope.maintenanceRequest.Jobs[j].hasExceededFileSize = false;

                    $scope.maintenanceRequest.Jobs[j].isCollapse = j > 0;

                    if ($scope.maintenanceRequest.Jobs[j].TenancyJobStatus === 'InProgress') {
                        $scope.maintenanceRequest.Jobs[j].color = 'orange';
                    } else if ($scope.maintenanceRequest.Jobs[j].TenancyJobStatus === 'Completed') {
                        $scope.maintenanceRequest.Jobs[j].color = 'green';
                    } else if ($scope.maintenanceRequest.Jobs[j].TenancyJobStatus === 'Cancelled') {
                        $scope.maintenanceRequest.Jobs[j].color = 'grey';
                    } else {
                        $scope.maintenanceRequest.Jobs[j].color = 'blue';
                    }


                    $scope.maintenanceRequest.Jobs[j].statusList = angular.copy(statusList);
                }
            }
        }

        function setOwnerJobModel() {
            if ($scope.maintenanceRequest && $scope.maintenanceRequest.Jobs.length > 0) {
                for (var j = 0; j < $scope.maintenanceRequest.Jobs.length; j++) {
                    $scope.maintenanceRequest.Jobs[j].files = [];
                    $scope.maintenanceRequest.Jobs[j].hasExceededFileSize = false;
                    $scope.maintenanceRequest.Jobs[j].isCollapse = j > 0;

                    if ($scope.maintenanceRequest.Jobs[j].OwnerJobStatus === 'QuoteRequest') {
                        $scope.maintenanceRequest.Jobs[j].color = 'orange';
                    } else if ($scope.maintenanceRequest.Jobs[j].OwnerJobStatus === 'WorkOrder') {
                        $scope.maintenanceRequest.Jobs[j].color = 'dark blue';
                    } else if ($scope.maintenanceRequest.Jobs[j].OwnerJobStatus === 'Invoiced') {
                        $scope.maintenanceRequest.Jobs[j].color = 'yellow';
                    } else if ($scope.maintenanceRequest.Jobs[j].OwnerJobStatus === 'Completed') {
                        $scope.maintenanceRequest.Jobs[j].color = 'green';
                    } else if ($scope.maintenanceRequest.Jobs[j].OwnerJobStatus === 'Cancelled') {
                        $scope.maintenanceRequest.Jobs[j].color = 'grey';
                    } else {
                        $scope.maintenanceRequest.Jobs[j].color = 'blue';
                    }


                    $scope.maintenanceRequest.Jobs[j].statusList = angular.copy(statusList);

                    var filteredCreditorsList = _.filter($scope.maintenanceRequest.Jobs[j].Creditors, function (creditor) {
                        return creditor.HasQuote;
                    });

                    var approvedCreditor = _.filter($scope.maintenanceRequest.Jobs[j].Creditors, function (creditor) {
                        return creditor.QuoteApproved;
                    });

                    if (filteredCreditorsList && filteredCreditorsList.length > 0) {
                        var item = { CreditorName: 'None of these quotes are acceptable', HasQuote: true, Quote: '', Value: 'None', JobCreditorReference: '' };
                        _.each(filteredCreditorsList, function (creditor) {
                            creditor.Value = creditor.CreditorName;
                        });

                        if ($scope.maintenanceRequest.Jobs[j].HasQuoteApprovalPending) {
                            filteredCreditorsList.push(item);
                        }
                        
                        $scope.maintenanceRequest.Jobs[j].filteredCreditorsList = filteredCreditorsList;
                        $scope.maintenanceRequest.Jobs[j].hasApprovedCreditor = approvedCreditor.length > 0;
                    }

                    if ($scope.maintenanceRequest.Jobs[j].OwnerJobStatus === 'WorkOrder' || $scope.maintenanceRequest.Jobs[j].OwnerJobStatus === 'Invoiced' || $scope.maintenanceRequest.Jobs[j].OwnerJobStatus === 'Completed') {
                        for (var k = 0; k < $scope.maintenanceRequest.Jobs[j].Invoices.length; k++) {
                            var invoice = $scope.maintenanceRequest.Jobs[j].Invoices[k];
                            if (invoice.HasAttacments) {
                                invoice.popover = {
                                    scopeHelper: $scope,
                                    options: {
                                        html: true,
                                        container: '#attachments_' + invoice.InvoiceNumber,
                                        content: '',
                                        placement: 'top'
                                    },
                                };

                                for (var i = 0; i < invoice.Attachments.length; i++) {
                                    var local = '<div><a href="' + invoice.Attachments[i].Url + '" target="_blank" class="link-black">' + invoice.Attachments[i].FileName + '</a><div class="spacer10"></div></div></div>';
                                    invoice.popover.options.content = invoice.popover.options.content + local;
                                }
                            }

                            invoice.popoverdesc = {
                                scopeHelper: $scope,
                                options: {
                                    html: true,
                                    container: 'body',
                                    content: '<div><span>' + invoice.Description + '</span><div>',
                                    placement: 'top'
                                },
                            };
                        }
                    }
                }
            }

            console.log('check', $scope.maintenanceRequest.Jobs);
        }

        $scope.updateSelectedCreditor = function (job, creditor) {
            _.each($scope.maintenanceRequest.Jobs, function(item) {
                if (job.JobReference === item.JobReference) {
                    item.creditor = creditor;
                }

                if (creditor.Value !== 'None') {
                    job.approveId = ownerApprovalenumList[0].id;
                }
            });
        };

        $scope.approveRequest = function () {
            $scope.sendMRequest = true;
            var option = _.filter($scope.optionList, function (item) {
                return item.isSelected;
            });

            var propData = {
                CompanyReference: $scope.maintenanceRequest.CompanyReference,
                ContactReference: $scope.maintenanceRequest.ContactReference,
                MaintenanceRequestReference: $scope.maintenanceRequest.MaintenanceRequestReference,
                Response: option[0].id,
                Details: $scope.maintenanceRequest.ownerInstructions,
                JobReference: '',
                JobCreditorReference: ''
            };

            var saveApproveRequest = mrApi.approveRequest(propData).$promise;
            saveApproveRequest.then(function (success) {
                $scope.sendMRequest = false;
                shell.flash.success('Maintenance request approval sent');
                init();
            }, function (error) {
                $scope.sendMRequest = false;
                console.log(error);
            });
        };

        $scope.approveQuoteRequest = function (job) {
            $scope.sendJobResponse = true;
            var option = _.filter($scope.jobsOptionList, function (item) {
                return item.isSelected;
            });

            var propData = {
                CompanyReference: $scope.maintenanceRequest.CompanyReference,
                ContactReference: $scope.maintenanceRequest.ContactReference,
                MaintenanceRequestReference: $scope.maintenanceRequest.MaintenanceRequestReference,
                Response: job.creditor && job.creditor.Value !== 'None' ? ownerApprovalenumList[0].id : option[0].id,
                Details: job.ownerInstructions,
                JobReference: job.JobRefernece,
                JobCreditorReference: job.creditor ? job.creditor.JobCreditorReference : ''
            };

            var saveApproveQuoteRequest = mrApi.approveRequest(propData).$promise;
            saveApproveQuoteRequest.then(function (success) {
                $scope.sendJobResponse = false;
                shell.flash.success('Quote request approval sent');
                init();
            }, function (error) {
                $scope.sendJobResponse = false;
                console.log(error);
            });
        };

        $scope.uploadAttachment = function (job) {

            var propData = {
                CompanyReference: $scope.maintenanceRequest.CompanyReference,
                ContactReference: $scope.maintenanceRequest.ContactReference,
                MaintenanceRequestReference: $scope.maintenanceRequest.MaintenanceRequestReference,
                JobReference: job.JobRefernece,
                Attachments: job.files.map(function (file) {
                    return file.mapForUpload();
                })
            };

            $scope.vm.updMRStatus = true;
            var createMR = mrApi.uploadMaintenanceJobAttachment(propData).$promise;
            createMR.then(function (sts) {
                $scope.vm.updMRStatus = false;
                shell.flash.success('Maintenance request updated');
                init();
            });
        };

        var onRouteChangeOff = $rootScope.$on('$stateChangeStart', routeChange);
        function routeChange(event, next, current) {
            var newFileCount = 0;
            _.each($scope.maintenanceRequest.Jobs, function (job) {
                newFileCount += job.files.length;
                if (newFileCount > 0) {
                    return;
                }
            });

            if (newFileCount > 0) {
                var buttonDefinition = [{ result: 'cancel', label: 'Cancel' }, { result: 'ok', label: 'Discard Changes', cssClass: 'primary' }];
                var message = '<p>Your changes has not been saved.<br/>Are you sure you want to leave this page and discard your changes?<p>';
                shell.confirmBox('Confirm Cancel Changes', message, buttonDefinition, true)
                .result.then(function (result) {
                    if (result == "ok") {
                        onRouteChangeOff();
                        if (next.name === 'shell.manageInspections') {
                            $state.go(next.name, { profileType: 'tenant', inspectionId: $rootScope.inspectionId });
                        } else {
                            $state.go(next.name, { profileType: $stateParams.profileType });
                        }
                    }
                });

                event.preventDefault();
                return;
            } else {
                onRouteChangeOff();
            }
        }
    }

})();
