(function() {
    'use strict';

    angular.module('pt.controllers')
        .controller('createMaintenanceRequestCtrl', controllerFn);

    controllerFn.$inject = ['$scope', '$rootScope', '$stateParams', '$state', 'resourceFactory', 'shell'];
    function controllerFn($scope, $rootScope, $stateParams, $state, resourceFactory, shell) {

        var MRAPI = resourceFactory.create('maintenanceRequest');
        var messagesApi = resourceFactory.create('messages');
        var isTenancy = /tenan/gi.test($stateParams.profileType);
        $rootScope.ownerTheme =  isTenancy ? false:true;

        var profiles = _.filter($rootScope.user.Profiles, function (profile) {
            if(isTenancy && profile.IsCurrentTenancy)
                return true;
            else if(!isTenancy && /management/gi.test(profile.ProfileType)){
                return true;
            }
            return false;
        });

        $scope.vm = {
            profiles:[],
            selectedProfile:[],
            summary:'',
            description:'',
            files:[],
            createMRStatus: false,
            hasExceededFileSize: false,
            firstRun:true
        };

        function isMessagesSwitchedOff() {
            messagesApi.isMessagesSwitchedOff({}, function (data) {
                $scope.isMessagesSwitchedOff = data.IsMessagesSwitchedOff;
            });
        }

        isMessagesSwitchedOff();

        $scope.vm.profiles = profiles;
        if(profiles.length === 1) 
            $scope.vm.selectedProfile = profiles[0];


        $scope.submit = function() {
            $scope.vm.firstRun = false;
            var selItem = $scope.vm.selectedProfile;

            var propData = {
                CompanyReference: selItem.CompanyReference,
                ContactReference: selItem.ContactReference,
                PropertyReference: selItem.PropertyReference,
                ProfileType:isTenancy ? 'Tenancy' : 'Owner',
                Summary: $scope.vm.summary,
                Details: $scope.vm.description,
                Attachments: $scope.vm.files.map(function(file){
                    return file.mapForUpload();
                })
            };

            if($scope.crMReqForm.$valid){
                $scope.vm.createMRStatus = true;
                var createMR = MRAPI.create(propData).$promise;
                createMR.then(function(sts){
                    $scope.crMReqForm.$setPristine();
                    shell.flash.success('Maintenance request sent for review');
                    $state.go('shell.viewMaintenanceRequests', {profileType: isTenancy?'tenant':'owner'});
                });
            }
        };

        $scope.popover = {
            scopeHelper: $scope,
            options: {
                html: true,
                container: '#cancelButton',
                content: 
                    '<div>Are you sure you want to cancel and discard this form?</div>' +
                    '<div class="spacer10"></div>' +
                    '<div class="clearfix">' +
                        '<div class="pull-right">' +
                            '<button type="button" class="btn secondary" ng-click="closePopover($event)"><b>No</b></button>' +
                            '&nbsp;&nbsp;&nbsp;&nbsp;<button type="button" class="btn primary" ng-click="confirmCancel()">Yes</button>'+
                        '</div>' +
                    '</div>',
                placement: 'top'
            },
        };

        $scope.closePopover = function (event) {
            $scope.popover.options.dismissPopover();
            event.stopPropagation();
        };

        $scope.confirmCancel = function(){
            $state.go('shell.viewMaintenanceRequests', {profileType:(isTenancy)? 'tenant':'owner'});
        };

        var onRouteChangeOff = $rootScope.$on('$stateChangeStart', routeChange);
        function routeChange(event, next, current) {
            if ($scope.crMReqForm.$dirty ||  ($scope.vm.files.length > 0 && $scope.vm.firstRun)) {
                var buttonDefinition = [{ result: 'cancel', label: 'Cancel' }, { result: 'ok', label: 'Discard Changes', cssClass: 'primary' }];
                var message = '<p>Your changes has not been saved.<br/>Are you sure you want to leave this page and discard your changes?<p>';
                shell.confirmBox('Confirm Cancel Changes', message, buttonDefinition, true)
                .result.then(function (result) {
                    if (result == "ok") {
                        onRouteChangeOff();
                        if (next.name === 'shell.manageInspections') {
                            $state.go(next.name, { profileType: 'tenant', inspectionId: $rootScope.inspectionId });
                        } else {
                            $state.go(next.name, { profileType: $stateParams.profileType });
                        }
                    }
                });

                event.preventDefault();
                return;
            } else {
                onRouteChangeOff();
            }
        }

        $scope.$watch('vm.summary', function(newval, oldval) {
            if(newval && newval.trim() !== '')
                $scope.crMReqForm.summary.$setValidity('summary', true);
            else
                $scope.crMReqForm.summary.$setValidity('summary', false);
        });

        $scope.$watch('vm.description', function (newval, oldval) {
            if(newval && newval.trim() !== '')
                $scope.crMReqForm.description.$setValidity('description', true);
            else
                $scope.crMReqForm.description.$setValidity('description', false);
        });

    }

})();
