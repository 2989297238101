"use strict";
angular.module('pt.directives')

    .directive('tdLineGraph', ['usSpinnerService', '$timeout',

        function (usSpinnerService, $timeout) {
            return {
                templateUrl: '/partials/common/directives/tdLineGraph.html',
                scope: {
                    data: '=',
                    labels: '=',
                    series: '=',
                    options: '='
                },
                link: function (scope, ele, attrs) {
                    
                }
            };
        }
    ]);