(function() {
    'use strict';
    var _$q;

    var FileWrapper = function (file) {
        this._realFile = file;
    };
    FileWrapper.fn = FileWrapper.prototype;

    FileWrapper.fn.getThumbnail = function(){
        var self = this;
    };

    Object.defineProperty(FileWrapper.prototype, 'name', {
        get: function () { return this._realFile.name
            .substr(0, this._realFile.name.indexOf('.', -1))
            .substr(0,15).trim(); 
        },
        set: undefined
    });

    Object.defineProperty(FileWrapper.prototype, 'extension', {
        get: function () { return this._realFile.name.substr(this._realFile.name.indexOf('.', -1)); },
        set: undefined
    });

    Object.defineProperty(FileWrapper.prototype, 'type', {
        get: function () { return this._realFile.type; },
        set: undefined
    });

    Object.defineProperty(FileWrapper.prototype, 'size', {
        get: function () { return this._realFile.size; },
        set: undefined
    });

    Object.defineProperty(FileWrapper.prototype, 'data', {
        get: function () { return this._data; },
        set: undefined
    });

    FileWrapper.fn.readAsync = function () {
        var deferred = _$q.defer();

        var reader = new FileReader();
        var self = this;

        reader.onload = function (e) {
            self._data = e.target.result;
            deferred.resolve(self);
        };

        reader.onerror = function (e) {
            self._error = e.target.error;
            deferred.reject(self);
        };

        reader.readAsDataURL(this._realFile);

        return deferred.promise;
    };
    FileWrapper.fn.mapForUpload = function() {
        return {
            Name: this.name,
            Extension: this.extension,
            MimeType: this.type,
            Data: this.data.substring(this.data.indexOf(',') + 1)
        };
    };


    angular.module('pt.directives')
        .directive('tdFileAdd', directiveFn);

    directiveFn.$inject = ['$rootScope', '$q', '$window'];
    function directiveFn($rootScope, $q, $window) {
        _$q = $q;
        var directive = {
            restrict:'E',
            scope:{},
            replace:true,
            template: 
                '<div class="td-file-add">' +
                    '<div class="dd-area" ng-show="validFileAPI">' +
                    '<img src="dist/images/drag-and-drop.svg" width=77 height=62><br>' +
                        'DRAG AND DROP YOUR IMAGES / ATTACHMENTS <br>or <a href="#" class="link-blue">Browse for files</a>' +
                    '</div>' +
                    '<div class="dd-fallback" ng-show="!validFileAPI"><input type="file" multiple></input></div>' + 
                '</div>',
            compile:compileFn
        };
        return directive;

        function compileFn(tEle, tAttrs, transclude) {
            return postLinkFn;

            function postLinkFn(scope, elem, attr) {
                scope.validFileAPI = ($window.FileReader) ? true: false;

                var inputFile = elem.find('input[type="file"]')[0];
                var ddArea = elem.find('.dd-area')[0];
                var ddLink = elem.find('.dd-area a')[0];

                $(inputFile).bind("change", function (changeEvent) {
                    var files = [];
                    for (var i = 0; i < changeEvent.target.files.length; i++) {
                        var file = new FileWrapper(changeEvent.target.files[i]);
                        files.push(file);
                    }
                    changeEvent.target.value = "";
                    $rootScope.$broadcast('tdFileAdd:FileAdded', files);
                });

                $(ddArea).click(function(){
                    $(inputFile).trigger('click');
                });

                $(ddLink).click(function(e){
                    e.stopPropagation();
                    e.preventDefault();
                    $(inputFile).trigger('click');
                });

                $(ddArea).on('dragenter',function(e){
                    e.stopPropagation();
                    e.preventDefault();
                    $(this).addClass('hover');
                });
                $(ddArea).on('dragover', function(e){
                    e.stopPropagation();
                    e.preventDefault();
                    $(this).addClass('hover');
                });
                $(ddArea).on('dragleave', function(e){
                    e.stopPropagation();
                    e.preventDefault();
                    $(this).removeClass('hover');
                });
                $(ddArea).on('drop', function(e){
                    e.stopPropagation();
                    e.preventDefault();
                    $(this).removeClass('hover');
                    var files = [];
                    _.each(e.originalEvent.dataTransfer.files, function(ddFile){
                        files.push(new FileWrapper(ddFile));
                    });
                    $rootScope.$broadcast('tdFileAdd:FileAdded', files);
                });
            }
        }
    }

})();
