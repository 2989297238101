'use strict';

// Various utility functions. Overkill to inject these as angular services all the time, so just register as a global.

/*jshint -W079 */ // stop jshint choking on this because since PTUtils is in the rc file it thinks we are 'redefining' it. duh.
var PTUtils = function() {

    function padLeft(padding, value) {
        return (padding + value.toString()).slice(-padding.length);
    }

    // Return an ISO string for the date, but without the timezone and without it being converted to UTC first!
    function toUnspecifiedISOString(date, dateOnly) {
        if (dateOnly) {
            return padLeft("0000", date.getFullYear()) +
                "-" + padLeft("00", date.getMonth() + 1) +
                "-" + padLeft("00", date.getDate());
        }

        return padLeft("0000", date.getFullYear()) +
            "-" + padLeft("00", date.getMonth() + 1) +
            "-" + padLeft("00", date.getDate()) +
            "T" + padLeft("00", date.getHours()) +
            ":" + padLeft("00", date.getMinutes()) +
            ":" + padLeft("00", date.getSeconds()) +
            "." + padLeft("000", date.getMilliseconds());
    }

    return {
        padLeft: padLeft,
        toUnspecifiedISOString: toUnspecifiedISOString
    };
}();
