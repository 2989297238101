/*jshint browser: true */
'use strict';

// Declare app level module which depends on filters, and services
(function () {
    angular.module('pt', [
      'smart-table',
      'ng-context-menu',
      'ui.router',
      'ngResource',
      'ngSanitize',
      'ngAnimate',
      'ngTouch',
      'ui.tinymce',
      'ngProgress',
      'ngIntercom',
      'angularSpinner',
      'ui.router.breadcrumbs',
      'ui.bootstrap',
      'perfect_scrollbar',
      'pt.filters',
      'pt.services',
      'pt.directives',
      'pt.controllers',
      'duScroll',
      'angulartics',
      'angulartics.google.analytics',
      'chart.js'
    ])

    .factory('authInterceptor', ['$q', '$window', '$injector', function ($q, $window, $injector) {
        return {
            request: function (config) {
                if (config.url.indexOf(PTConfig.APP_API_BASE_URL) === 0) {
                    // only modify calls to the tardis API site.
                    config.headers = config.headers || {};
                    config.headers['Content-Type'] = 'application/json; charset=utf-8';    // force JSON for all requests
                    config.withCredentials = true;  // allow api site to receive and set cookies
                }

                return config;
            },
            responseError: function (rejection) {
                var $state = $injector.get('$state');

                if (rejection.status === 401) {
                    $state.go("login");
                } else if (rejection.status === 503) {
                    $window.location.reload();
                }
                else if (rejection.status >= 500) {
                    $state.go("shell.connectionDown");
                }

                return $q.reject(rejection);
            },
        };
    }])

    .config(['$stateProvider', '$urlRouterProvider', '$locationProvider',
        '$httpProvider',
        '$intercomProvider',
        'ChartJsProvider',
        function ($stateProvider, $urlRouterProvider, $locationProvider, $httpProvider, $intercomProvider, ChartJsProvider) {

            $intercomProvider
                .appID(PTConfig.INTERCOM_APP_ID)
                .asyncLoading(true);

            ChartJsProvider.setOptions({
                colours: ['#5ebc77', '#cb2027'],
                tooltipFillColor: "#ccc",
                tooltipFontColor: "#000",
                tooltipTitleFontColor: "#000",
                scaleShowLabels: true,
                responsive: true
            });

             //Configure all line charts
            ChartJsProvider.setOptions('Line', {
                datasetFill: false,
                scaleShowVerticalLines: false,
                scaleGridLineColor: "#ccc",
            });

            $urlRouterProvider.otherwise(function ($injector, $location) {
                var path = $location.path();
                if (path === "/") return "/home";

                return '/error/page-not-found';
            });

            $stateProvider
                .state('invitation', {
                    url: "^/invitation?invitation",
                    templateUrl: 'partials/common/login.html',
                    controller: 'loginCtrl',
                })
                .state('login', {
                    url: "^/login?returnUrl&cantLogin&r",
                    templateUrl: 'partials/common/login.html',
                    controller: 'loginCtrl',
                })
                .state('error', {
                    url: "^/error",
                    templateUrl: 'partials/common/error/error.html',
                    controller: 'errorCtrl',
                })
                .state('terms', {
                    url: '^/termsofuse',
                    templateUrl: 'partials/common/termsOfUse.html',
                    controller: 'termsOfUseCtrl',
                })
                .state('shell', {
                    url: '^/shell',
                    templateUrl: 'partials/common/shell.html',
                    controller: 'shellCtrl',
                    abstract: true,
                })
                .state('shell.home', {
                    url: '^/home',
                    templateUrl: 'partials/common/home.html',
                    breadcrumb: 'Home',
                    controller: 'homeCtrl',
                })
                .state('shell.reportsStatements', {
                    url: '^/home/owner/manage-properties/reportsStatements?reportType',
                    templateUrl: 'partials/ownership/reportsStatements.html',
                    breadcrumb: 'Reports & Statements',
                    controller: 'reportsStatementsCtrl',
                })
                .state('shell.receiptsAndInvoices', {
                    url: '^/tenancyReceiptsAndInvoice',
                    templateUrl: 'partials/tenancy/tenancyReceiptsAndInvoices.html',
                    controller: 'tenancyReceiptsAndInvoicesCtrl',
                })
                .state('shell.messages', {
                    url: '^/messages/:profileType',
                    templateUrl: 'partials/messaging/messagingCentre.html',
                    breadcrumb: 'Messages',
                    controller: 'messagingCentreCtrl',
                })
                .state('shell.messageCompose', {
                    url: '^/messages/:profileType/compose?managementId&tenancyId&threadId',
                    templateUrl: 'partials/messaging/compose.html',
                    breadcrumb: 'Compose',
                    controller: 'composeCtrl',
                })
                .state('shell.messageThreadView', {
                    url: '^/messages/:profileType/threadview?companyReference&participantsHash&threadId',
                    templateUrl: 'partials/messaging/threadView.html',
                    breadcrumb: 'MessageThreads',
                    controller: 'threadViewCtrl',
                })
                .state('shell.connectionDown', {
                    url: "^/error/connection-down",
                    templateUrl: 'partials/common/error/connectionDown.html',
                    controller: 'errorCtrl'
                })
                .state('shell.pageNotFound', {
                    url: "^/error/page-not-found",
                    templateUrl: 'partials/common/error/pageNotFound.html',
                    controller: 'errorCtrl'
                })
                .state('shell.manageInspections', {
                    url: "^/inspections/:profileType?managementId&inspectionId",
                    templateUrl: 'partials/tenancy/manageInspections.html',
                    controller: 'manageInspectionsCtrl'
                })
                .state('shell.viewMaintenanceRequests', {
                    url: "^/:profileType/maintenance-requests",
                    templateUrl: 'partials/maintenanceRequests/viewMaintenanceRequests.html',
                    controller: 'maintenanceRequestsCtrl',
                    breadcrumb: 'Maintenance Requests'
                })
                .state('shell.viewMyDocuments', {
                    url: "^/:profileType/my-documents",
                    templateUrl: 'partials/documents/document.html',
                    controller: 'documentCtrl',
                    breadcrumb: 'My Documents'
                })
                .state('shell.createMaintenanceRequests', {
                    url: "^/:profileType/maintenance-requests/create",
                    templateUrl: 'partials/maintenanceRequests/createMaintenanceRequest.html',
                    controller: 'createMaintenanceRequestCtrl',
                    breadcrumb: 'Create Maintenance Request'
                })
                .state('shell.viewMaintenanceRequestDetails', {
                    url: "^/:profileType/maintenance-requests/view?companyReference&contactReference&maintenanceRequestId",
                    templateUrl: 'partials/maintenanceRequests/viewMaintenanceRequestDetails.html',
                    controller: 'maintenanceRequestDetailsCtrl',
                    breadcrumb: 'Maintenance Request Details'
                })
                .state('shell.rentHistory', {
                    url: "^/:profileType/rentHistory",
                    templateUrl: 'partials/ownership/rentHistory.html',
                    controller: 'rentHistoryCtrl',
                    breadcrumb: 'Rent History'
                });

            $locationProvider.hashPrefix('!');
            $locationProvider.html5Mode(true);

            $httpProvider.interceptors.push('authInterceptor');

            // Auto convert date strings to real date objects, and ensures that timezone is ignored and always in local time.
            $httpProvider.defaults.transformResponse.push(function () {
                var regexIso8601 = /^(\d{4})-([01]\d)-([0-3]\d)T([0-2]\d):([0-5]\d)(?::([0-5]\d)(?:\.(\d{1,3}))?)?([+-][0-2]\d:[0-5]\d|Z)?$/;

                function convertDateStringsToDates(input) {
                    // Ignore things that aren't objects.
                    if (typeof input !== "object") return;

                    for (var key in input) {
                        if (!input.hasOwnProperty(key)) continue;

                        var value = input[key];
                        var match;
                        // Check for string properties which look like dates.
                        if (typeof value === "string" && (match = value.match(regexIso8601))) {
                            if (match[8] === undefined) {
                                // when no timezone, ensure it's parsed as local time (i.e. don't shift it or parse as a UTC time).
                                var year = parseInt(match[1]);
                                var month = parseInt(match[2]) - 1;
                                var day = parseInt(match[3]);
                                var hour = parseInt(match[4]);
                                var minute = parseInt(match[5]);
                                var second = match[6] === undefined ? 0 : parseInt(match[6]);
                                var milli = match[7] === undefined ? 0 : parseInt((match[7] + '00').substring(0, 3));

                                input[key] = new Date(year, month, day, hour, minute, second, milli);
                            } else {
                                var date = Date.parse(match[0]);
                                input[key] = new Date(date);
                            }
                        } else if (typeof value === "object") {
                            // Recurse into object
                            convertDateStringsToDates(value);
                        }
                    }
                }

                return function (responseData) {
                    convertDateStringsToDates(responseData);
                    return responseData;
                };
            }());

            // Ensure date is serialized always as local time with no timezone.
            Date.prototype.toJSON = function (key) {
                return PTUtils.toUnspecifiedISOString(this);
            };
        }])

    .run(['$rootScope', '$injector', '$window', function ($rootScope, $injector, $window, $location) {
        $rootScope.ownerTheme = true;

        $rootScope.$on('$stateChangeSuccess', function (e, to, toParams, from, fromParams) {
            if ($rootScope.user) {
                var state = $injector.get('$state');
                var activityUrl = state.href(to.name, toParams, { absolute: true });
                $window.Intercom('update', { email: $rootScope.user.Email, url: activityUrl });
            }
        });
    }]);
})();
