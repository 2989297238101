(function () {
    'use strict';
    angular.module('pt.controllers')
        .controller('documentCtrl', controllerFn);

    controllerFn.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'resourceFactory'];
    function controllerFn($scope, $rootScope, $state, $stateParams, resourceFactory) {       
        var TenancydocumentsApi = resourceFactory.create('TenantDocuments');
        var OwnerDocumentsApi = resourceFactory.create('OwnerDocuments');

        $scope.ownerStatementsSpinner = true;
        $rootScope.ownerTheme = true;
        $scope.profile = [];
        $scope.profileType = $stateParams.profileType;

        $scope.profile = _.filter($rootScope.user.Profiles, function (pr) {
            if ($scope.profileType == 'owner')
                return /Owner/gi.test(pr.ProfileType);
            else
                return /Tenancy/gi.test(pr.ProfileType);
        });

        $scope.myUser = $scope.profile[0];

        if ($scope.myUser.ProfileType == 'Tenancy') {
            getTenancyDocuments($scope.myUser);
        }
        else {
            getOwnerDocuments($scope.myUser);
        }

        function getTenancyDocuments(tenant) {
            TenancydocumentsApi.query({}, function (data) {
                $scope.myDocuments = data;
                $scope.ownerStatementsSpinner = false;
            }, function (error) {
                $scope.ownerStatementsSpinner = false;
            });
        }

        function getOwnerDocuments(owner) {
            OwnerDocumentsApi.query({}, function (data) {
                $scope.myDocuments = data;
                $scope.ownerStatementsSpinner = false;
            }, function (error) {
                $scope.ownerStatementsSpinner = false;
            });
        }

    }
})();


