"use strict";
angular.module('pt.directives')
    .constant('tdTimepickerConfig', {
        hourStep: 1,
        minuteStep: 1,
        showMeridian: true,
        meridians: ['AM', 'PM'],
        readonlyInput: false,
        mousewheel: true
    })
    .directive('tdTimePicker', ['$window', '$filter', 'tdTimepickerConfig', '$parse',
    function ($window, $filter, tdTimepickerConfig, $parse) {
        return {
            restrict: 'EA',
            require: 'ngModel',
            replace: true,
            templateUrl: '/partials/common/directives/tdTimePicker.html',
            scope: {
                model: '=ngModel'
            },
            link: function (scope, element, attrs, ngModelCtrl) {
                var selected = new Date(),
                    meridians = tdTimepickerConfig.meridians,
                    timeRegex = /^([0-2]\d):([0-5]\d)$/;

                selected.setHours(9);
                selected.setMinutes(0);
                scope.validHours = true;
                scope.validMinutes = true;
                scope.meridian = meridians[0];
                var hourStep = tdTimepickerConfig.hourStep;
                if (attrs.hourStep) {
                    scope.$parent.$watch($parse(attrs.hourStep), function (value) {
                        hourStep = parseInt(value, 10);
                    });
                }

                var minuteStep = tdTimepickerConfig.minuteStep;
                if (attrs.minuteStep) {
                    scope.$parent.$watch($parse(attrs.minuteStep), function (value) {
                        minuteStep = parseInt(value, 10);
                    });
                }

                if (attrs.minTime !== undefined) {
                    scope.$parent.$watch($parse(attrs.minTime), function (value) {
                        var match = value.match(timeRegex);
                        if (match) {
                            scope.minHour = parseInt(match[1], 10);
                            scope.minMins = parseInt(match[2], 10);
                        }
                    });
                }

                if (attrs.maxTime !== undefined) {
                    scope.$parent.$watch($parse(attrs.maxTime), function (value) {
                        var match = value.match(timeRegex);
                        if (match) {
                            scope.maxHour = parseInt(match[1], 10);
                            scope.maxMins = parseInt(match[2], 10);
                        }
                    });
                }

                function pad(value) {
                    return (angular.isDefined(value) && value.toString().length < 2) ? '0' + value : value;
                }

                // 12H / 24H mode
                scope.showMeridian = tdTimepickerConfig.showMeridian;
                if (attrs.showMeridian) {
                    scope.$parent.$watch($parse(attrs.showMeridian), function (value) {
                        scope.showMeridian = !!value;
                        if (scope.model !== null && scope.model !== undefined) {
                            refreshTemplate();
                        }
                    });
                }

                // Get scope.hours in 24H mode if valid
                function getScopeHours() {
                    var hours = parseInt(scope.hours, 10);
                    var valid = (scope.showMeridian) ? (hours > 0 && hours < 13) : (hours >= 0 && hours < 24);
                    if (!valid) {
                        return;
                    }

                    if (scope.showMeridian) {
                        if (hours === 12) {
                            hours = 0;
                        }
                        if (scope.meridian === meridians[1]) {
                            hours = hours + 12;
                        }
                    }
                    return hours;
                }

                function checkRange() {
                    var hours = getScopeHours();
                    var mins = parseInt(scope.minutes, 10);

                    if (scope.minHour !== undefined && (hours < scope.minHour || (hours === scope.minHour && mins < scope.minMins))) {
                        if (!scope.model) {
                            scope.model = new Date(selected);
                        }
                        scope.model.setHours(scope.minHour);
                        scope.model.setMinutes(scope.minMins);
                        return true;
                    }

                    if (scope.maxHour !== undefined && (hours > scope.maxHour || (hours === scope.maxHour && mins < scope.maxMins))) {
                        if (!scope.model) {
                            scope.model = new Date(selected);
                        }
                        scope.model.setHours(scope.maxHour);
                        scope.model.setMinutes(scope.maxMins);
                        return true;
                    }
                }

                // Input elements
                var inputs = element.find('input');
                var hoursInputEl = inputs.eq(0), minutesInputEl = inputs.eq(1);

                // Respond on mousewheel spin
                var mousewheel = (angular.isDefined(attrs.mousewheel)) ? scope.$eval(attrs.mousewheel) : tdTimepickerConfig.mousewheel;
                if (mousewheel) {

                    var isScrollingUp = function (e) {
                        if (e.originalEvent) {
                            e = e.originalEvent;
                        }
                        //pick correct delta variable depending on event
                        var delta = (e.wheelDelta) ? e.wheelDelta : -e.deltaY;
                        return (e.detail || delta > 0);
                    };

                    hoursInputEl.bind('mousewheel wheel', function (e) {
                        scope.$apply((isScrollingUp(e)) ? scope.incrementHours() : scope.decrementHours());
                        e.preventDefault();
                    });

                    minutesInputEl.bind('mousewheel wheel', function (e) {
                        scope.$apply((isScrollingUp(e)) ? scope.incrementMinutes() : scope.decrementMinutes());
                        e.preventDefault();
                    });
                }

                var keyboardChange = false;
                scope.readonlyInput = (angular.isDefined(attrs.readonlyInput)) ? scope.$eval(attrs.readonlyInput) : tdTimepickerConfig.readonlyInput;
                if (!scope.readonlyInput) {
                    scope.updateHours = function () {
                        if (scope.hours) {
                            var hours = getScopeHours();

                            if (angular.isDefined(hours)) {
                                keyboardChange = 'h';
                                if (!scope.model) {
                                    scope.model = new Date(selected);
                                }
                                scope.model.setHours(hours);
                            } else {
                                scope.model = null;
                                scope.validHours = false;
                            }
                        }
                        else {
                            scope.model = null;
                        }
                    };

                    hoursInputEl.bind('blur', function (e) {
                        scope.$apply(function () {
                            if (scope.validHours && scope.hours) {
                                if (checkRange()) return;

                                if (scope.hours < 10) {
                                    scope.hours = pad(scope.hours);
                                }

                                scope.model = new Date(selected);
                                return;
                            }

                            if (!scope.validHours || !scope.hours) {
                                scope.minutes = undefined;
                                scope.hours = undefined;
                            }
                        });
                    });

                    scope.updateMinutes = function () {
                        var minutes = parseInt(scope.minutes, 10);
                        if (minutes >= 0 && minutes < 60) {
                            keyboardChange = 'm';
                            if (!scope.model) {
                                scope.model = new Date(selected);
                            }
                            scope.model.setMinutes(minutes);
                        } else {
                            scope.model = null;
                            if (!minutes) {
                                scope.validMinutes = true;
                            }
                            else {
                                scope.validMinutes = false;
                            }
                        }
                    };

                    minutesInputEl.bind('blur', function (e) {
                        scope.$apply(function () {
                            if (scope.validMinutes && scope.minutes) {
                                if (checkRange()) return;

                                if (scope.minutes < 10) {
                                    scope.minutes = pad(scope.minutes);
                                }

                                scope.model = new Date(selected);
                                return;
                            }
                            if (!scope.validMinutes || !scope.minutes) {
                                scope.minutes = undefined;
                                scope.hours = undefined;
                            }
                        });
                    });
                } else {
                    scope.updateHours = angular.noop;
                    scope.updateMinutes = angular.noop;
                }

                scope.$watch(function getModelTimestamp() {
                    return +scope.model;
                }, function (timestamp) {
                    if (!isNaN(timestamp)) {
                        selected = new Date(timestamp);
                        refreshTemplate();
                    }
                });

                function refreshTemplate() {
                    var hours = selected.getHours();
                    if (scope.showMeridian) {
                        // Convert 24 to 12 hour system
                        hours = (hours === 0 || hours === 12) ? 12 : hours % 12;
                    }
                    scope.hours = (keyboardChange === 'h') ? hours : pad(hours);
                    scope.validHours = true;

                    var minutes = selected.getMinutes();
                    scope.minutes = (keyboardChange === 'm') ? minutes : pad(minutes);
                    scope.validMinutes = true;

                    scope.meridian = (scope.showMeridian) ? ((selected.getHours() < 12) ? meridians[0] : meridians[1]) : '';

                    keyboardChange = false;
                }

                function addMinutes(minutes) {
                    var dt = new Date(selected.getTime() + minutes * 60000);

                    var hours = dt.getHours();
                    var mins = dt.getMinutes();

                    if (scope.minHour !== undefined) {
                        if (hours < scope.minHour || (hours === scope.minHour && mins < scope.minMins)) {
                            hours = scope.minHour;
                            mins = scope.minMins;
                        }
                    }

                    if (scope.maxHour !== undefined) {
                        if (hours > scope.maxHour || (hours === scope.maxHour && mins > scope.maxMins)) {
                            hours = scope.maxHour;
                            mins = scope.maxMins;
                        }
                    }

                    selected.setHours(hours);
                    selected.setMinutes(mins);
                    scope.model = new Date(selected);
                }

                scope.incrementHours = function () {
                    addMinutes(hourStep * 60);
                };
                scope.decrementHours = function () {
                    addMinutes(-hourStep * 60);
                };
                scope.incrementMinutes = function () {
                    addMinutes(minuteStep);
                };
                scope.decrementMinutes = function () {
                    addMinutes(-minuteStep);
                };
                scope.toggleMeridian = function () {
                    addMinutes(12 * 60 * ((selected.getHours() < 12) ? 1 : -1));
                };
            }
        };
    }
    ]);

