"use strict";

angular.module('pt.directives')
    .directive("fileAdd", ['$q', function ($q) {

        var FileWrapper = function (file) {
            this._realFile = file;
        };

        Object.defineProperty(FileWrapper.prototype, 'name', {
            get: function () { return this._realFile.name; },
            set: undefined
        });

        Object.defineProperty(FileWrapper.prototype, 'type', {
            get: function () { return this._realFile.type; },
            set: undefined
        });

        Object.defineProperty(FileWrapper.prototype, 'size', {
            get: function () { return this._realFile.size; },
            set: undefined
        });

        Object.defineProperty(FileWrapper.prototype, 'data', {
            get: function () { return this._data; },
            set: undefined
        });

        FileWrapper.prototype.readAsync = function () {
            var deferred = $q.defer();

            var reader = new FileReader();
            var self = this;

            reader.onload = function (e) {
                self._data = e.target.result;
                deferred.resolve(self);
            };

            reader.onerror = function (e) {
                self._error = e.target.error;
                deferred.reject(self);
            };

            reader.readAsDataURL(this._realFile);

            return deferred.promise;
        };

        return {
            scope: {
                fileAdd: '&'
            },
            link: function (scope, element, attributes) {
                element.bind("change", function (changeEvent) {
                    scope.$apply(function () {
                        for (var i = 0; i < changeEvent.target.files.length; i++) {
                            var file = new FileWrapper(changeEvent.target.files[i]);
                            scope.fileAdd({ file: file });
                        }

                        changeEvent.target.value = "";
                    });
                });
            }
        };
    }]);
