(function() {
    'use strict';

    angular.module('pt.services')
        .factory('maintenanceRequestSvc', factoryFn);

    factoryFn.$inject = [];
    function factoryFn() {
        
        function mapMRStsToCss(mrSts){
            var className = '';
            switch(mrSts.toLowerCase()){
                case '' : className = 'yellow-bg'; break;
                case 'completed' : className = 'green-bg'; break;
                case 'open' : className = 'blue-bg'; break;
                case '' : className = 'dark-blue-bg'; break;
                case '' : className = 'orange-bg'; break;
                default : className = 'dark-grey-bg'; break;
            }

            return className;
        }

        return {
            MapMRStsToCss:mapMRStsToCss
        };
    }

})();
