"use strict";
angular.module('pt.directives')

    .directive('tdNewLineGraph', ['usSpinnerService', '$timeout',

        function (usSpinnerService, $timeout) {
            return {
                template: '<div><canvas id="line"></canvas></div>',
                scope: {
                    lineData: '=',
                    options: '='
                },
                link: function (scope, elem, attrs) {
                    scope.setGraph = function () {
                        var ctx = elem.find('canvas')[0].getContext("2d");
                        var myBar = new window.Chart(ctx).Line(scope.lineData, scope.options);
                    };

                    scope.$watch('lineData', function (val) {
                        if (val) {
                            $timeout(function () {
                                scope.setGraph();
                            });
                        }

                    });
                }
            };
        }
    ]);