'use strict';
angular.module('pt.directives').
  directive('niceScroll', function() {
    return  {
        link: function (scope, element, attrs) {
            var anchor = '#' + attrs.id;
            element.wrap("<div style=height:" + attrs.newheight + "></div>");           
            element.parent().niceScroll(anchor, { cursorcolor: "#ed6245", cursorwidth: 10, autohidemode: false, background: "#efefef" });
        }
    };
  });
