(function() {
    'use strict';

    angular.module('pt.directives')
        .directive('tdPageHeader', directiveFn);

    directiveFn.$inject = [];
    function directiveFn() {
        var directive = {
            restrict:'E',
            scope:{},
            replace:true,
            transclude:true,
            template:
                '<div class="page-header-holder">' +
                    '<div class="page-header">'+
                        '<h1 ng-bind="headingText"></h1>' +
                        '<div class="actions" ng-transclude>' +
                        '</div>' +
                    '</div>' +
                    '<div class="shader"></div>' +
                '<div>',
            compile:compileFn
        };
        return directive;

        function compileFn(tEle, tAttrs, transclude) {
            return postLinkFn;

            function postLinkFn(scope, elem, attr) {
                scope.headingText = attr.header;
            }
        }
    }

})();
