"use strict";
angular.module('pt.services')
    .factory('shell', [
        'flash',
        '$rootScope',
        '$location',
        '$window',
        '$uibModal',
        'resourceFactory',   

        function (flash, $rootScope, $location, $window, $uibModal,resourceFactory) {
            var msgBoxTemplate = "<div class=\"modal-header tenant-style\">\n" +
                           "	<span id=\"confirmHeader\">{{ title }}</span>\n" +
                           "</div>\n" +
                           "<div ng-if=\"!allowHtml\" class=\"modal-body\">\n" +
                           "	<p id=\"confirmInfo\">{{ message }}</p>\n" +
                           "</div>\n" +
                           "<div ng-if=\"allowHtml\" class=\"modal-body\">\n" +
                           "	<div id=\"confirmSecondInfo\" ng-bind-html=\"message\"></div>\n" +
                           "</div>\n" +
                           "<div class=\"modal-footer\">\n" +
                           "	<button id=\"confirmBtn-{{$index}}\" ng-repeat=\"btn in buttons\" ng-click=\"close(btn.result)\" class=\"btn\" ng-class=\"btn.cssClass\">{{ btn.label }}</button>\n" +
                           "</div>\n" + "";

            var dialogStack = [];

            function trackDialog(dlg) {
                dialogStack.push(dlg);
                dlg.result.then(function (val) {
                    var prevDlg;

                    if (dialogStack.length > 1) {
                        prevDlg = dialogStack[dialogStack.length - 2];
                    }
                    dialogStack.pop();
                });
            }

            function promptLocationChange(event) {
                if (event.defaultPrevented || event.__pt_alreadyPrompted) return;

                // stops multiple prompts for the same event.
                event.__pt_alreadyPrompted = true;

                if (dialogStack.length > 0) {
                    if (!$window.confirm("Are you sure you want to cancel the dialog and leave this page?")) {
                        event.preventDefault();
                    }
                    else {
                        for (var i = dialogStack.length - 1; i >= 0; i--) {
                            var dlg = dialogStack[i];
                            dlg.close();
                        }
                    }
                } else {
                    if (!$window.confirm("Are you sure you want to leave this page?")) {
                        event.preventDefault();
                    }
                }
            }

            function getMsgBox(title, message, buttonDefinition, allowHtml) {
                var msgBoxOption = {
                    template: msgBoxTemplate,
                    controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                        $scope.title = title;
                        $scope.message = message;
                        $scope.buttons = buttonDefinition;
                        $scope.allowHtml = allowHtml;
                        $scope.close = function (val) {
                            $uibModalInstance.close(val);
                        };
                    }]
                };
                var dlg = $uibModal.open(msgBoxOption);
                trackDialog(dlg);
                return dlg;
            }

            //$rootScope.$on('$locationChangeStart', function (event, next, current) {
            //    if (dialogStack.length > 0) {
            //        promptLocationChange(event);
            //    }
            //});

            return {
                /**
                * Set the title of the shell
                */
                setTitle: function (title) {
                    $rootScope.pageTitle = title;
                },

                flash: flash,

                /**
                * Creates a modal editor dialog
                */

                createDialog: function (opts) {
                    opts.backdrop = 'static';
                    opts.keyboard = false;
                    var dlg = $uibModal.open(opts);
                    trackDialog(dlg);
                    return dlg;
                },

                /**
                * Creates a modal confirmation box
                */
                confirmBox: function (title, message, btns, allowHtml) {
                    var buttonDefinition = [{ result: 'cancel', label: 'Cancel' }, { result: 'ok', label: 'OK', cssClass: 'primary' }];
                    if (btns !== undefined) {
                        if (angular.isArray(btns)) {
                            buttonDefinition = btns;
                        } else if (btns === "YesNo") {
                            buttonDefinition = [{ result: 'no', label: 'No' }, { result: 'yes', label: 'Yes', cssClass: 'primary' }];
                        }
                    }

                    return getMsgBox(title, message, buttonDefinition, allowHtml);
                },

                /**
                * Creates a modal message box
                */
                messageBox: function (title, message, allowHtml) {
                    var buttonDefinition = [{ result: 'ok', label: 'OK', cssClass: 'pull-right' }];
                    return getMsgBox(title, message, buttonDefinition, allowHtml);
                },

                /**
                * Prompts the user if they want to navigate away
                */
                promptLocationChange: promptLocationChange,

                back: function () {
                    $window.history.back();
                },

            };
        }
    ]);


