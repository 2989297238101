'use strict';

/* Controllers */
(function () {
    angular.module('pt.controllers')
        .controller('shellCtrl', ['$rootScope', '$scope', '$http', '$state', '$window', '$interval', 'resourceFactory', 'ngProgressFactory', '$uibModal', 'shell', 'usSpinnerService',
            '$intercom',
            function ($rootScope, $scope, $http, $state, $window, $interval, resourceFactory, ngProgressFactory, $uibModal, shell, usSpinnerService, $intercom) {

            $scope.helpUrl = PTConfig.APP_API_BASE_URL + '/Utility/Help';
            $scope.everythingLoaded = false;
            $scope.email = '';
            $scope.totalUnreadCount = 0;
            $scope.isOwner = false;
            $scope.isTenant = false;
            $scope.sidebarDisplay = false;
            $scope.items = {
                companyReference: '',
                contactReference: '',
                tenancyReference: ''
            };

            $scope.activeContactList = [];

            $scope.progressbar = ngProgressFactory.createInstance();

            var messagesApi = resourceFactory.create('messages'),
            profileApi = resourceFactory.create('profile'),
            inspectionapi = resourceFactory.create('inspection');

            $scope.reloadMessaging = function () {
                if ($state.current.name !== 'shell.messages') {
                    $state.go('shell.messages', {
                        profileType: $scope.isOwner ? 'owner' : 'tenant'
                    });
                } else {
                    $rootScope.$broadcast('LoadMessages');
                }
            };

            $scope.loadUserInfo = function () {
                delete $rootScope.user;
                $scope.progressbar.start();
                profileApi.get()
                    .$promise.then(function(data){
                        if (data.Contacts && data.Contacts.length === 0) {
                            $scope.logout(true);
                        }
                        else {
                            $rootScope.user = data;

                            $scope.email = $rootScope.user.Email;
                            $scope.userName = $rootScope.user.Name;
                            $scope.showAccount = $rootScope.user.ProviderType == 'propertytree';
                            $window.localStorage.lastLoggedInSource = $rootScope.user.ProviderType;

                            $scope.loadProfiles();
                            $rootScope.$broadcast('UserLoggedIn');

                            $intercom.boot({
                                name: $rootScope.user.Name,
                                firstName: $rootScope.user.GivenName,
                                lastName: $rootScope.user.FamilyName,
                                email: $rootScope.user.Email,
                                created_at: $rootScope.user.CreatedAt,
                                provider: $rootScope.user.ProviderType,
                                widget: {
                                    activator: '#intercomWidget'
                                }
                            });
                        }
                    })
                    .finally(function () {
                        $scope.progressbar.complete();
                    });
            };

            $scope.loadProfiles = function () {
                profileApi.profiles({ profileType: '' }, function (data) {
                    $scope.profileData = data;
                    $rootScope.user.Profiles = data;

                    if ($scope.profileData && $scope.profileData.length === 0) {
                        $scope.progressbar.complete();
                        $scope.logout(true);
                    } else {
                        for (var i = 0; i < $scope.profileData.length; i++) {
                            if ($scope.profileData[i].ProfileType === 'Owner') {
                                $scope.isOwner = true;
                                $scope.profileType = "owner";
                                $scope.activeContactList.push($scope.profileData[i]);
                            }

                            if ($scope.profileData[i].ProfileType === 'Tenancy') {
                                if (!$scope.tenancy) {
                                    $scope.tenancy = $scope.profileData[i];
                                }

                                if ($scope.profileData[i].Inspection) {
                                    $scope.inspection = $scope.profileData[i].Inspection;
                                    $rootScope.inspectionId = $scope.inspection.InspectionID;
                                }
                                if (!$scope.isTenant) {
                                    $scope.items.companyReference = $scope.profileData[i].CompanyReference;
                                    $scope.items.contactReference = $scope.profileData[i].ContactReference;
                                    $scope.items.tenancyReference = $scope.profileData[i].ProfileReference;
                                }

                                $scope.isTenant = true;
                                $scope.profileType = "tenant";
                                $scope.activeContactList.push($scope.profileData[i]);
                            }

                            $intercom.update({
                                email: $rootScope.user.Email,
                                isTenant: $scope.isTenant,
                                isOwner:$scope.isOwner
                            });
                        }

                        $scope.progressbar.complete();
                        $scope.everythingLoaded = true;
                        $scope.getUnreadCounts($scope.activeContactList);
                    }
                });
            };

            $scope.loadInspection = function () {
                var params = {
                    CompanyReference: $scope.tenancy.CompanyReference,
                    ContactReference: $scope.tenancy.ContactReference,
                    ProfileReference: $scope.tenancy.ProfileReference
                };

                inspectionapi.getInspectionByEntityID(params, function (data) {
                    $scope.inspection = data && data.length > 0 ? data[0] : '';
                    if ($scope.inspection) {
                        $state.go('shell.manageInspections', {
                            profileType: 'tenant',
                            inspectionId: $scope.inspection.InspectionID
                        });
                    } else {
                        $state.go('shell.manageInspections', {
                            profileType: 'tenant'
                        });
                    }

                }, function (error) {
                });
            };

            $scope.checkConnectionDown = function () {
                if ($state.current.name === 'shell.connectionDown') {
                    $scope.everythingLoaded = true;
                }
            };

            $scope.gotoInspections = function () {
                $scope.loadInspection();
            };

            $scope.checkConnectionDown();

            $rootScope.$on('$stateChangeSuccess', $scope.checkConnectionDown);

            $scope.getUnreadCounts = function (items) {
                var params = [];
                for (var i = 0; i < items.length; i++) {
                    var local = {
                        CompanyReference: items[i].CompanyReference,
                        ContactReference: items[i].ContactReference,
                        ContactReferenceType: items[i].ProfileType
                    };

                    params.push(local);
                }
                messagesApi.totalUnreadMessageCount(params, function success(data) {
                    $scope.totalUnreadCount = data.UnreadCount;
                });
            };

            $scope.classText = 'animated '; // adding an animated class here interferes with boostrap default animation e.g. 'animated fadeInLeft'

            var opts = {
                templateUrl: '/partials/tenancy/tenancyLedgerDialog.html',
                controller: 'modalInstanceCtrl',
                windowClass: 'animated ', // animated class here interferes with boostrap default animation e.g. 'animated fadeInLeft'
                size: 'lg',
                resolve: {
                    items: function() {
                        return $scope.items;
                    },
                    profileData:function(){
                        return $scope.profileData;
                    },
                    type: function() {
                        return "tenant";
                    }
                }
            };

            $scope.tenancyLedger = function () {
                var dlg = shell.createDialog(opts);

                dlg.result.then(function (val) {
                    //opts.windowClass = 'animated fadeInRight';
                });
            };

            var ownershipDialogOpt = {
                templateUrl: '/partials/ownership/ownershipLedgerDialog.html',
                controller: 'modalInstanceCtrl',
                windowClass: 'animated ', // animated class here interferes with boostrap default animation e.g. 'animated fadeInLeft'
                size: 'lg',
                resolve: {
                    items: function() {
                        return $scope.items;
                    },
                    profileData:function(){
                        return $scope.profileData;
                    },
                    type: function() {
                        return "owner";
                    }
                }
            };

            $scope.ownershipLedger = function () {
                var dlg = shell.createDialog(ownershipDialogOpt);

                dlg.result.then(function (val) {
                    //opts.windowClass = 'animated fadeInRight';
                });
            };

            var updateAccountOpts = {
                templateUrl: '/partials/common/updateAccount.html',
                controller: 'updateAccountCtrl',
                windowClass: 'animated ',
                size: 'md'
            };

            $scope.updateAccount = function () {
                var dlg = shell.createDialog(updateAccountOpts);

                dlg.result.then(function (val) {
                    if (val) {
                        $scope.loadUserInfo();
                    }
                });
            };

            $scope.logout = function (invalidUser) {
                var accountApi = resourceFactory.create('account');

                delete $rootScope.user;

                accountApi.logout().$promise.finally(function () {
                    if (invalidUser) {
                        $state.go('login', {
                            cantLogin: true
                        });
                    } else {
                        $state.go('login');
                    }
                });
            };

            var sidebarFocus = false;

            $scope.sidebarToggle = function () {
                $scope.sidebarDisplay = !$scope.sidebarDisplay;
                return;
            };

            $scope.sidebarBlurToggle = function(){
                if ($scope.sidebarDisplay){
                    $scope.sidebarDisplay = !$scope.sidebarDisplay;
                    sidebarFocus = $scope.sidebarDisplay;
                }
            };

            $scope.$on('ReloadUnreadMessages', $scope.getUnreadCounts);

            $interval(function () {
                if ($rootScope.user) {
                    $rootScope.$broadcast('ReloadUnreadMessages');
                }
            }, PTConfig.UNREAD_MESSAGE_POLL_INTERVAL * 1000);

            $scope.loadUserInfo();
        }])

        .controller('errorCtrl', ['$scope', '$window', function ($scope, $window) {
            $scope.message = $window.sessionStorage.errorMessage;
        }])
        .controller('modalInstanceCtrl', [
            '$scope', 
            '$uibModalInstance',
            'items', 
            'type', 
            'profileData',
            'resourceFactory',
            '$intercom',
            '$window',

            function ($scope, $uibModalInstance, items, type, profileData, resourceFactory, $intercom, $window) {
            $scope.items = items;
            $scope.showFromDateText = false;
            $scope.showToDateText = false;
            $scope.selectedOwner = null;
            $scope.owners = [];

            function getOwners() {
                $scope.selectedOwner = null;
                $scope.owners = _.filter(profileData, {ProfileType:'Owner'});
                if($scope.owners.length > 0){
                    $scope.selectedOwner = $scope.owners[0];
                }
            }
            if(type==="owner"){
                getOwners();
            }

            $scope.close = function () {
                if (!$scope.checkValidDate($scope.fromDate) && !$scope.checkValidDate($scope.toDate)) {
                    if ($scope.toDate <= $scope.fromDate || $scope.toDate > $scope.maxFromDate) {
                        $scope.showToDateText = true;
                        $scope.errorText = 'Invalid date range - select a "To" date that is on or after the "From" date';
                        return;
                    } else {
                        $scope.showFromDateText = false;
                        $scope.showToDateText = false;

                        if(type === "owner")
                            $window.Intercom("trackEvent", "view-ownership-ledger");
                        else
                            $window.Intercom("trackEvent","view-tenancy-ledger");

                        $uibModalInstance.close();
                    }
                } else {
                    if (!$scope.checkValidDate($scope.fromDate)) {
                        $scope.showFromDateText = false;
                    } else {
                        $scope.showFromDateText = true;
                    }

                    if (!$scope.checkValidDate($scope.toDate)) {
                        $scope.showToDateText = false;
                    } else {
                        $scope.showToDateText = true;
                        $scope.errorText = 'Please select a valid date.';
                    }

                    return;
                }
            };

            $scope.selectOwner = function(owner){
                $scope.selectedOwner = owner;
            };

            $scope.$watch('fromDate', function(val) {
                if (val && !$scope.checkValidDate($scope.fromDate)) {
                    $scope.showFromDateText = false;
                }
            });

            $scope.$watch('toDate', function (val) {
                if (val && !$scope.checkValidDate($scope.toDate)) {
                    $scope.showToDateText = false;
                }
            });

            $scope.cancel = function () {
                $uibModalInstance.dismiss();
            };

            $scope.checkValidDate = function (value) {
                if (value !== undefined && value !== null && value !== '') {
                    if (value instanceof Date) {
                        var date = Date.parse(value);
                        if (date < 0) {
                            return true;
                        } else {
                            if (isNaN(date)) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            };

            var today = new Date();
            $scope.fromDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
            $scope.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
            $scope.maxFromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
            $scope.maxToDate = $scope.maxFromDate;

            $scope.buildReportUrl = function () {

                var statementType = "Tenancies";
                var companyRef = $scope.items.companyReference;
                var contactRef = $scope.items.contactReference;
                var refCode = $scope.items.tenancyReference;
                if(type === "owner") {
                    statementType = "Owners";
                    refCode = $scope.selectedOwner.ProfileReference;
                    companyRef = $scope.selectedOwner.CompanyReference;
                    contactRef = $scope.selectedOwner.ContactReference;
                }

                if (!$scope.checkValidDate($scope.fromDate) && !$scope.checkValidDate($scope.toDate) && $scope.toDate > $scope.fromDate && $scope.toDate <= $scope.maxFromDate)
                {
                    return PTConfig.APP_API_BASE_URL +
                        "/" + statementType + "/" + companyRef +
                        "/" + contactRef +
                        "/" + refCode +
                        "/LedgerReport" +
                        "?startDate=" + PTUtils.toUnspecifiedISOString($scope.fromDate) +
                        "&endDate=" + PTUtils.toUnspecifiedISOString($scope.toDate);
                }
                
                return '';
            };
        }])
        .controller('needanAccountCtrl', ['$scope', '$uibModalInstance', 'items', 'type', 'resourceFactory', function ($scope, $uibModalInstance, items, type, resourceFactory) {

            $scope.cancel = function() {
                $uibModalInstance.close();
            };
        }]);

})();
