(function() {
    'use strict';

    angular.module('pt.directives')
        .directive('tdBoxCard', directiveFn);

    directiveFn.$inject = [];

    function directiveFn() {
        var directive = {
            restrict: 'E',
            scope: {},
            transclude: true,
            template:
                '<div class="box-card card-grey">' +
                    '<div class="card-header" ng-show="showTitle">' +
                        '<div template-location="header"></div>' +
                    '</div>' +
                    '<div class="card-content">' +
                    '<div template-location="body"></div>' +
                    '</div>' +
                    '<div class="card-footer-bottom" ng-show="showFooter">' +
                        '<div template-location="footer"></div>' +
                    '</div>' +
                '</div>',
            link: link
        };

        return directive;

        function link(scope, elem, attrs, ctrls, transclude) {
            scope.showTitle = false;
            scope.showFooter = false;
            transclude(function(clone) {
                angular.forEach(clone, function(cloneEle) {
                    if (cloneEle.attributes) {
                        var tId = cloneEle.attributes['template-location'].value;
                        var target = elem.find('[template-location="' + tId + '"]');
                        if (tId === 'header') {
                            scope.showTitle = true;
                        }

                        if (tId === 'footer') {
                            scope.showFooter = true;
                        }

                        if (target) {
                            target.append(cloneEle);
                        }
                    }
                });

            });
        }
    }

})();
