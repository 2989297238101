'use strict';

/* Controllers */
(function () {
    angular.module('pt.controllers')
        .controller('tenancyReceiptsAndInvoicesCtrl', ['$scope', '$rootScope', '$http', 'resourceFactory', '$window', '$filter', 'usSpinnerService', '$state', '$interval',
            "$intercom",
             function ($scope, $rootScope, $http, resourceFactory, $window, $filter, usSpinnerService, $state, $interval, $intercom) {

            //------------------------------------------------------------------------------------------------
            // Tenancy Receipts
            //------------------------------------------------------------------------------------------------

            var profileApi = resourceFactory.create('profile'),
                tenancyapi = resourceFactory.create('tenancies'),
                receiptapi = resourceFactory.create('receipts');
            $scope.disabled = true;
            $scope.spinneractive = false;
            $rootScope.ownerTheme = false;  // styling the theme of the page based on role
            $scope.showCancelledReceipts = false;
            $scope.showReceipts = false;
            $scope.showInvoices = false;
            $scope.displaySelectedFilters = 'Show All Invoices';

            $scope.receiptsFilterChange = function () {
                $scope.filteredReceipts = $filter('filter')($scope.receipts, function (element) {
                    if (!$scope.showCancelledReceipts) {
                        return !element.IsCancelled;
                    }
                    return true;
                });
                $scope.displayReceipts = [].concat($scope.filteredReceipts);
            };

            function getReceipts(tenant) {
                usSpinnerService.spin('receipts-1');
                tenancyapi.receipts({ CompanyReference: tenant.CompanyReference, ContactReference: tenant.ContactReference, TenancyReference: tenant.ProfileReference }, function (data) {
                    $scope.receipts = data;
                    $scope.receiptsFilterChange($scope.showCancelledReceipts);
                    $scope.displayReceipts = [].concat($scope.filteredReceipts);
                    usSpinnerService.stop('receipts-1');
                });
            }

            function getTenant() {
                profileApi.profiles({ profileType: 'Tenancy' }, function (data) {
                    $scope.tenants = data;
                    if ($scope.tenants.length > 0) {
                        $scope.selectedTenant = $scope.tenants[0];
                    }
                });
            }

            $scope.hasMultipleTenancies = function () {
                return ($scope.tenants && $scope.tenants.length > 1);
            };

            $scope.tenancySelected = function (tenancy) {
                $scope.selectedTenant = tenancy;
            };

            $scope.receiptUrl = function (receipt) {
                if (receipt) {
                    return PTConfig.APP_API_BASE_URL +
                        "/receipts/" + $scope.selectedTenant.CompanyReference +
                        "/" + $scope.selectedTenant.ContactReference +
                        "/" + receipt.ReceiptReference;
                }

                return "";
            };

            $scope.openReceipt = function(){
                $window.Intercom("trackEvent", "view-tenancy-receipt");

            };

            $scope.receiptTabSelected = function () {
                $scope.showReceipts = true;
                $scope.showInvoices = false;
                getReceipts($scope.selectedTenant);
            };

            $scope.$watch('selectedTenant', function(val) {
                if (val) {
                    if ($scope.showReceipts) {
                        getReceipts($scope.selectedTenant);
                    } else if ($scope.showInvoices) {
                        getInvoices($scope.selectedTenant);
                    }
                }
            });

            //------------------------------------------------------------------------------------------------
            // Tenancy Invoices
            //------------------------------------------------------------------------------------------------
            var invoiceapi = resourceFactory.create('tenancyInvoices');
            $scope.filterTypes = [{ Name: 'Cancelled', isChecked: false },
                                  { Name: 'Paid', isChecked: true },
                                  { Name: 'Part Paid', isChecked: true },
                                  { Name: 'Unpaid', isChecked: true },
                                  { Name: 'Overdue', isChecked: true }];


            //var filterType = filterTypes[0];
            $scope.invoicesFilterChange = function () {
                $scope.filteredInvoices = $filter('filter')($scope.invoices, function (item) {
                    var allUncheckStatus = true;
                    for (var i = 0; i < $scope.filterTypes.length; i++) {
                        if ($scope.filterTypes[i].isChecked && $scope.filterTypes[i].Name === item.StatusDisplay) {
                            return true;
                        } else {
                            if ($scope.filterTypes[i].Name === 'Overdue' && $scope.filterTypes[i].isChecked) {
                                if (item.IsOverdue) {
                                    return true;
                                }
                            }
                        }

                        if ($scope.filterTypes[i].isChecked) {
                            allUncheckStatus = false;
                        }
                    }

                    return allUncheckStatus;
                });

                $scope.displayInvoices = [].concat($scope.filteredInvoices);
            };

            $scope.$watch('filterTypes', function (val) {
                if (val) {
                    $scope.displaySelectedFilters = '';
                    var localItems = [];
                    for (var i = 0; i < val.length; i++) {
                        if (val[i].isChecked) {
                            localItems.push(val[i].Name);
                        }
                    }

                    $scope.displaySelectedFilters = localItems.length === 5 || localItems.length === 0 ? 'Show All Invoices' : localItems.join(", ") + ' Invoices';
                }
            }, true);

            function getInvoices(tenant) {
                usSpinnerService.spin('invoices-1');
                tenancyapi.invoices({ CompanyReference: tenant.CompanyReference, ContactReference: tenant.ContactReference, TenancyReference: tenant.ProfileReference }, function (data) {
                    $scope.invoices = data;
                    usSpinnerService.stop('invoices-1');
                    $scope.displayInvoices = $scope.invoices;
                    insertPopoverOptions();
                    insertPopoverOptionsForDescription();
                    $scope.invoicesFilterChange();
                    $interval(function () {
                        $scope.resetThisthingy();
                    }, 300, 1);
                });
            }

            $scope.resetThisthingy = function () {
                var localTable = $scope.displayInvoices;
                for (var i = 0; i < localTable.length; i++) {
                    var description = $('#desc_' + i);
                    if (description.width() > 700) {
                        localTable[i].isOverFlowing = true;
                    } else {
                        localTable[i].isOverFlowing = false;
                    }

                }
            };

            $scope.invoiceTabSelected = function () {
                $scope.showReceipts = false;
                $scope.showInvoices = true;
                getInvoices($scope.selectedTenant);
            };

            function insertPopoverOptions() {
                _($scope.displayInvoices).forEach(function (statement) {
                    var index = _.indexOf($scope.displayInvoices, statement);
                    if (statement.HasCreditorInvoiceAttachments) {
                        statement.popover = {
                            scopeHelper: $scope,
                            options: {
                                html: true,
                                container: '#attachments_' + statement.InvoiceReference,
                                content: '',
                                placement: 'top'
                            },
                        };

                        for (var i = 0; i < $scope.displayInvoices[index].CreditorInvoiceAttachments.length; i++) {
                            var local = '<div><a href="' + $scope.displayInvoices[index].CreditorInvoiceAttachments[i].Url + '" target="_blank" class="link-black">' + $scope.displayInvoices[index].CreditorInvoiceAttachments[i].FileName + '</a></div></div>';
                            statement.popover.options.content = statement.popover.options.content + local;
                        }
                    }
                });
            }

            function insertPopoverOptionsForDescription() {
                _($scope.displayInvoices).forEach(function (statement) {
                    var index = _.indexOf($scope.displayInvoices, statement);
                    statement.popoverdesc = {
                        scopeHelper: $scope,
                        options: {
                            html: true,
                            container: '#invDesc_' + statement.InvoiceReference,
                            content: '<div><span>' + $scope.displayInvoices[index].Description + '</span><div>',
                            placement: 'top'
                        },
                    };
                });
            }


            $scope.toggleDropdown = function ($event) {
                $event.stopPropagation();
            };

            $scope.filterSelection = function () {
                $scope.invoicesFilterChange();
            };

            $scope.invoiceUrl = function (invoice) {
                if (invoice) {

                    return PTConfig.APP_API_BASE_URL +
                        "/invoices/TenancyInvoices/" + $scope.selectedTenant.CompanyReference +
                        "/" + $scope.selectedTenant.ContactReference +
                        "/" + invoice.InvoiceReference;
                }

                return "";
            };

            $scope.openInvoice = function(){
                $window.Intercom("trackEvent", "view-tenancy-invoice");
            };


            //////
            //
            /////
            $scope.showReceipts = true;
            $scope.showInvoices = false;
            getTenant();
        }]);
})();
