"use strict";

angular.module('pt.services')
    .factory('resourceFactory', [
        '$resource',        
        '$window',
        function ($resource, $window) {

            function createApiResource(url, params, actions) {

                var defaultActions = {
                    remove: { method: 'DELETE' },
                    create: { method: 'POST' },
                    update: { method: 'PUT' }
                };

                if (ng.isObject(actions)) {
                    ng.extend(defaultActions, actions);
                }

                return $resource(PTConfig.APP_API_BASE_URL + url, params, defaultActions);
            }

            var ng = angular;

            var registrations = {};
            var resourceFactory = {
                create: function (name) {
                    if (!registrations.hasOwnProperty(name))
                        throw new IllegalArgumentException('Cannot create a resource using the provided name');
                    return registrations[name]();
                },
                register: function (name, url, params, actions) {

                    // registers a factory method so that not instantiated until requested.
                    registrations[name] = function () {
                        return createApiResource(url, params, actions);
                    };

                    return this; // chainable
                }
            };

            return resourceFactory;
        }
    ]);