'use strict';
angular.module('pt.services')
  .factory('enums', [
        '$rootScope',
        function ($rootScope) {
            return {
                MaintenanceOwnerApprovalOption: {
                    genericEnum: {
                        Approve: 1,
                        OwnerAttending: 2,
                        Reject: 3,
                        MoreInfoRequired: 4
                    },
                    displayEnum: {
                        Approve: { id: 1, optionText: 'I approve this request' },
                        OwnerAttending: { id: 2, optionText: 'I will attend to this request myself' },
                        Reject: { id: 3, optionText: 'I do not wish to proceed with this request' },
                        MoreInfoRequired: { id: 4, optionText: 'I need more information' }
                    },
                    displayEnumList: [{ id: 1, optionText: 'I approve this request' },
                                      { id: 2, optionText: 'I will attend to this request myself' },
                                      { id: 3, optionText: 'I do not wish to proceed with this request' },
                                      { id: 4, optionText: 'I need more information' }]
                }
            };
        }
  ]);