(function () {
    /* Setup the resource registrations for this module */
    "use strict";

    angular.module('pt.controllers').run([
        'resourceFactory',
        function (resourceFactory) {
            resourceFactory
                .register('TenantDocuments','/Tenancies/DMSDocuments/:action',
                { },
                {})

                .register('OwnerDocuments', '/Owners/DMSDocuments/:action',
                {},
                {});

        }
    ]);

})();