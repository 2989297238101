"use strict";
angular.module('pt.directives')

    .directive('tdOptionList', ['usSpinnerService', '$timeout',

        function (usSpinnerService, $timeout) {
            return {
                templateUrl: '/partials/common/directives/tdOptionList.html',
                scope: {
                    optionList: '='
                },
                link: function (scope, ele, attrs) {
                    scope.optionSelected = function(option) {
                        for (var i = 0; i < scope.optionList.length; i++) {
                            if (option.id === scope.optionList[i].id) {
                                scope.optionList[i].isSelected = true;
                            } else {
                                scope.optionList[i].isSelected = false;
                            }
                        }
                    };
                }
            };
        }
    ]);