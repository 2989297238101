(function() {
    'use strict';

    angular.module('pt.directives')
        .directive('tdBoxStatusCard', directiveFn);

    directiveFn.$inject = ['maintenanceRequestSvc'];
    function directiveFn(maintenanceRequestSvc) {
        var directive = {
            restrict:'E',
            scope: {
                mreq: '='
            },
            transclude:true,
            replace:true,
            template: 
            '<div class="box-card card-grey box-status-card">' +
                '<div ng-if="mreq.HasApprovalPending" class="td-approval"><a ui-sref="shell.viewMaintenanceRequestDetails({profileType: \'\owner\'\, companyReference: mreq.CompanyReference,' +
                    'contactReference: mreq.ContactReference, maintenanceRequestId: mreq.MaintenanceRequestReference})"><img class="pull-right" src="dist/images/approval-required.svg" width="155" height="58"></a></div>' +
                '<div class="card-header">' +
                    '<span>{{ mreq.Summary }}</span>' +
                '</div>' +
                '<div class="card-status-block animated fadeIn align-center" ng-class="statusStyle">' +
                    '<span>{{ mreq.DisplayStatus }}</span>' +
                '</div>' +
                '<div class="card-content" ng-transclude>' +
                '</div>' +
            '</div>',
            compile:compileFn
        };
        return directive;

        function compileFn(tEle, tAttrs, transclude) {
            return postLinkFn;

            function postLinkFn(scope, elem, attr) {
                scope.statusStyle = {};
                scope.statusStyle[maintenanceRequestSvc.MapMRStsToCss(scope.mreq.DisplayStatus)] = true;

            }
        }
    }

})();
