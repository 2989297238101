'use strict';

/* Controllers */
(function () {
    angular.module('pt.controllers')
      .controller('loginCtrl', ['$scope', '$stateParams', '$window', '$state', '$location', 'shell', '$rootScope', 'resourceFactory', function ($scope, $stateParams, $window, $state, $location, shell, $rootScope, resourceFactory) {

          var accountApi = resourceFactory.create('account');

          $scope.model = { };

          $scope.isInvited = $stateParams.invitation ? true : false;
          $scope.currentYear = new Date().getFullYear();

          if ($window.localStorage.lastLoggedInSource) {
              switch ($window.localStorage.lastLoggedInSource) {
                  case 'google-oauth2':
                      $scope.lastLoggedInSource = 'Google';
                      break;
                  case 'facebook':
                      $scope.lastLoggedInSource = 'Facebook';
                      break;
                  case 'windowslive':
                      $scope.lastLoggedInSource = 'Microsoft';
                      break;
                  case 'twitter':
                      $scope.lastLoggedInSource = 'Twitter';
                      break;
                  case 'linkedin':
                      $scope.lastLoggedInSource = 'LinkedIn';
                      break;
                  case 'propertytree':
                      $scope.lastLoggedInSource = 'PropertyTree';
                      break;
              }
          }

          $scope.aboutPage = function () {
              $scope.aboutClicked = true;
          };

          $scope.closeAbout = function () {
              $scope.aboutClicked = false;
          };

          $scope.socialLogin = function (connection) {
              var url = PTConfig.APP_API_BASE_URL + '/account/login/' + connection;
              if ($stateParams.invitation) {
                  url = url + "?Invitation=" + $stateParams.invitation;
              } else if ($stateParams.returnUrl) {
                  url = url + "?ReturnUrl=" + $stateParams.returnUrl;
              } else if ($stateParams.r) {
                  url = url + "?HelpGizmo=" + $stateParams.r;
              }

              $window.location = url;
          };

          $scope.redirectTo = function (url) {
            $window.open(url);
         };

          $scope.login = function () {
              $scope.model.loginerror = false;
              $scope.model.loginbusy = true;

              accountApi.login({
                  Email: $scope.model.email,
                  Password: $scope.model.password,
                  Invitation: $stateParams.invitation,
                  ReturnUrl: $stateParams.returnUrl,
                  HelpGizmo: $stateParams.r
              }, function (data) {
                  $scope.model.loginbusy = false;

                  if (data.Error) {
                      $scope.model.loginerror = true;
                  }
                  else {
                      $window.location = data.RedirectTo;
                  }
              }, function (response) {
                  $scope.model.loginbusy = false;
                  $scope.model.loginerror = true;
              });
          };

          // Forgot Password dialog -----------------------

          var resetPasswordOpts = {
              templateUrl: '/partials/common/resetPassword.html',
              controller: 'resetPasswordCtrl',
              windowClass: 'animated ',
              size: 'md',
          };

          $scope.resetPassword = function () {
              var dlg = shell.createDialog(resetPasswordOpts);

              dlg.result.then(function (val) {
                  if (val) {
                      $window.location = val;
                  }
              });
          };

          // Create Account dialog ------------------------

          var createAccountOpts = {
              templateUrl: '/partials/common/createAccount.html',
              controller: 'createAccountCtrl',
              windowClass: 'animated ',
              size: 'md',
              resolve: {
                  invitation: function () {
                      return $stateParams.invitation;
                  }
              }
          };

          $scope.createAccount = function () {
              var dlg = shell.createDialog(createAccountOpts);

              dlg.result.then(function (val) {
                  if (val) {
                      $window.location = val;
                  }
              });
          };

          // Cant login dialog ----------------------------

          var loginOpts = {
              templateUrl: '/partials/common/cantLoginDialog.html',
              controller: 'cantLoginCtrl',
              windowClass: 'animated ',
              size: 'md',
              resolve: {
                  items: function () {
                      return $scope.items;
                  },
                  type: function () {
                      return "";
                  }
              }
          };

          $scope.cantLogin = function () {
              var dlg = shell.createDialog(loginOpts);

              dlg.result.then(function (val) {
              });
          };

          // ----------------------------------------------

          if ($stateParams.cantLogin === 'true') {
              $scope.cantLogin();
          }

      }])
      .controller('cantLoginCtrl', ['$scope', '$uibModalInstance', 'items', 'type', 'resourceFactory', function ($scope, $uibModalInstance, items, type, resourceFactory) {
          $scope.cancel = function () {
              $uibModalInstance.close();
          };
      }])
      .controller('createAccountCtrl', ['$scope', '$uibModalInstance', 'resourceFactory', 'invitation', function ($scope, $uibModalInstance, resourceFactory, invitation) {
          var accountApi = resourceFactory.create('account');

          $scope.model = {
              busy: false
          };

          $scope.sendValidationCode = function () {
              if (!$scope.userDetailsForm.emailAddress.$valid) return;

              $scope.model.busy = true;

              accountApi.validateEmail({
                  EmailAddress: $scope.model.emailAddress,
              }, function (data) {
                  $scope.model.busy = false;
              }, function (response) {
                  $scope.model.busy = false;
              });
          };

          $scope.save = function () {
              validateForm();

              if (!$scope.userDetailsForm.$valid) return;

              $scope.model.busy = true;

              accountApi.signup({
                  EmailAddress: $scope.model.emailAddress,
                  EmailValidationCode: $scope.model.validationCode,
                  Password: $scope.model.password,
                  GivenName: $scope.model.givenName,
                  FamilyName: $scope.model.familyName,
                  Invitation: invitation
              }, function (data) {
                  $scope.model.busy = false;
                  $uibModalInstance.close(data.RedirectTo);
              }, function (response) {
                  $scope.model.busy = false;
                  $scope.model.error = response.data;
              });
          };

          $scope.cancel = function () {
              $uibModalInstance.close();
          };

          function validateForm() {
              // Check all of the fields
              validateEmailAddress($scope.model.emailAddress);
              validateEmailValidationCode($scope.model.validationCode);
              validateGivenName($scope.model.givenName);
              validateFamilyName($scope.model.familyName);
              validatePassword($scope.model.password);
              validateConfirmPassword($scope.model.confirmPassword);
          }

          function validateRequired(field, newval) {
              var isValid = !!newval && newval.trim() !== '';
              field.$setValidity('required', isValid);
              return isValid;
          }

          function validateEmailAddress(newval) {

              validateRequired($scope.userDetailsForm.emailAddress, newval);
          }

          function validateEmailValidationCode(newval) {
              validateRequired($scope.userDetailsForm.validationCode, newval);
          }

          function validateGivenName(newval) {
              validateRequired($scope.userDetailsForm.givenName, newval);
          }

          function validateFamilyName(newval) {
              validateRequired($scope.userDetailsForm.familyName, newval);
          }

          function validatePassword(newval) {
              validateRequired($scope.userDetailsForm.password, newval);
          }

          function validateConfirmPassword(newval) {
              var hasValue = validateRequired($scope.userDetailsForm.confirmPassword, newval);
              $scope.userDetailsForm.confirmPassword.$setValidity('match', !hasValue || newval === $scope.model.password);
          }

          $scope.$watch('model.emailAddress', validateEmailAddress);
          $scope.$watch('model.validationCode', validateEmailValidationCode);
          $scope.$watch('model.givenName', validateGivenName);
          $scope.$watch('model.familyName', validateFamilyName);
          $scope.$watch('model.password', validatePassword);
          $scope.$watch('model.confirmPassword', validateConfirmPassword);
      }])
      .controller('resetPasswordCtrl', ['$scope', '$uibModalInstance', 'resourceFactory', function ($scope, $uibModalInstance, resourceFactory) {
          var accountApi = resourceFactory.create('account');

          $scope.model = {
              busy: false
          };

          $scope.sendValidationCode = function () {
              if (!$scope.userDetailsForm.emailAddress.$valid) return;

              $scope.model.busy = true;

              accountApi.validateEmail({
                  EmailAddress: $scope.model.emailAddress,
              }, function (data) {
                  $scope.model.busy = false;
              }, function (response) {
                  $scope.model.busy = false;
              });
          };

          $scope.save = function () {
              validateForm();

              if (!$scope.userDetailsForm.$valid) return;

              $scope.model.busy = true;

              accountApi.resetPassword({
                  EmailAddress: $scope.model.emailAddress,
                  EmailValidationCode: $scope.model.validationCode,
                  NewPassword: $scope.model.password
              }, function (data) {
                  $scope.model.busy = false;
                  $uibModalInstance.close(data.RedirectTo);
              }, function (response) {
                  $scope.model.busy = false;
                  $scope.model.error = response.data;
              });
          };

          $scope.cancel = function () {
              $uibModalInstance.close();
          };

          function validateForm() {
              // Check all of the fields
              validateEmailAddress($scope.model.emailAddress);
              validateEmailValidationCode($scope.model.validationCode);
              validatePassword($scope.model.password);
              validateConfirmPassword($scope.model.confirmPassword);
          }

          function validateRequired(field, newval) {
              var isValid = !!newval && newval.trim() !== '';
              field.$setValidity('required', isValid);
              return isValid;
          }

          function validateEmailAddress(newval) {

              validateRequired($scope.userDetailsForm.emailAddress, newval);
          }

          function validateEmailValidationCode(newval) {
              validateRequired($scope.userDetailsForm.validationCode, newval);
          }

          function validatePassword(newval) {
              validateRequired($scope.userDetailsForm.password, newval);
          }

          function validateConfirmPassword(newval) {
              var hasValue = validateRequired($scope.userDetailsForm.confirmPassword, newval);
              $scope.userDetailsForm.confirmPassword.$setValidity('match', !hasValue || newval === $scope.model.password);
          }

          $scope.$watch('model.emailAddress', validateEmailAddress);
          $scope.$watch('model.validationCode', validateEmailValidationCode);
          $scope.$watch('model.password', validatePassword);
          $scope.$watch('model.confirmPassword', validateConfirmPassword);
      }])
      .controller('updateAccountCtrl', ['$scope', '$uibModalInstance', 'resourceFactory', function ($scope, $uibModalInstance, resourceFactory) {
          var accountApi = resourceFactory.create('account');

          $scope.model = {
              busy: true
          };

          $scope.sendValidationCode = function () {
              if (!$scope.userDetailsForm.emailAddress.$valid) return;

              $scope.model.busy = true;

              accountApi.validateEmail({
                  EmailAddress: $scope.model.emailAddress,
              }, function (data) {
                  $scope.model.busy = false;
              }, function (response) {
                  $scope.model.busy = false;
              });
          };

          $scope.save = function () {
              validateForm();

              if (!$scope.userDetailsForm.$valid) return;

              $scope.model.busy = true;

              accountApi.update({
                  EmailAddress: $scope.model.oldEmailAddress,
                  Password: $scope.model.oldPassword,
                  NewEmailAddress: $scope.model.emailAddress,
                  EmailValidationCode: $scope.model.validationCode,
                  NewPassword: $scope.model.password,
                  GivenName: $scope.model.givenName,
                  FamilyName: $scope.model.familyName
              }, function (data) {
                  $scope.model.busy = false;
                  $uibModalInstance.close(true);
              }, function (response) {
                  $scope.model.busy = false;
                  $scope.model.error = response.data;
              });
          };

          $scope.cancel = function () {
              $uibModalInstance.close();
          };

          $scope.validationCodeRequired = function () {
              // only required when we change the email address
              var email = $scope.model.emailAddress;
              if (email !== null && email !== undefined) email = email.toLowerCase();
              var oldEmail = $scope.model.oldEmailAddress;
              if (oldEmail !== null && oldEmail !== undefined) oldEmail = oldEmail.toLowerCase();

              return oldEmail !== email;
          };

          $scope.passwordConfirmationRequired = function () {
              // only required when we change the password
              return !!$scope.model.password && $scope.model.password.trim() !== '';
          };

          function validateForm() {
              // Check all of the fields
              validateEmailAddress($scope.model.emailAddress);
              validateEmailValidationCode($scope.model.validationCode);
              validateGivenName($scope.model.givenName);
              validateFamilyName($scope.model.familyName);
              validateOldPassword($scope.model.oldPassword);
              validateConfirmPassword($scope.model.confirmPassword);
          }

          function validateRequired(field, newval) {
              var isValid = !!newval && newval.trim() !== '';
              field.$setValidity('required', isValid);
              return isValid;
          }

          function validateEmailAddress(newval) {

              validateRequired($scope.userDetailsForm.emailAddress, newval);
          }

          function validateEmailValidationCode(newval) {
              if ($scope.validationCodeRequired()) {
                  validateRequired($scope.userDetailsForm.validationCode, newval);
              } else {
                  $scope.userDetailsForm.validationCode.$setValidity('required', true);
              }
          }

          function validateGivenName(newval) {
              validateRequired($scope.userDetailsForm.givenName, newval);
          }

          function validateFamilyName(newval) {
              validateRequired($scope.userDetailsForm.familyName, newval);
          }

          function validateOldPassword(newval) {
              validateRequired($scope.userDetailsForm.oldPassword, newval);
          }

          function validateConfirmPassword(newval) {
              if ($scope.passwordConfirmationRequired()) {
                  var hasValue = validateRequired($scope.userDetailsForm.confirmPassword, newval);
                  $scope.userDetailsForm.confirmPassword.$setValidity('match', !hasValue || newval === $scope.model.password);
              } else {
                  $scope.userDetailsForm.confirmPassword.$setValidity('required', true);
                  $scope.userDetailsForm.confirmPassword.$setValidity('match', true);
              }
          }

          $scope.$watch('model.emailAddress', validateEmailAddress);
          $scope.$watch('model.validationCode', validateEmailValidationCode);
          $scope.$watch('model.givenName', validateGivenName);
          $scope.$watch('model.familyName', validateFamilyName);
          $scope.$watch('model.oldPassword', validateOldPassword);
          $scope.$watch('model.confirmPassword', validateConfirmPassword);

          accountApi.get({}, function (data) {
              $scope.model.busy = false;
              $scope.model.oldEmailAddress = data.EmailAddress;
              $scope.model.emailAddress = data.EmailAddress;
              $scope.model.givenName = data.GivenName;
              $scope.model.familyName = data.FamilyName;
          }, function (response) {
              $scope.model.busy = false;
              $scope.model.error = response.data;
          });
      }]);
})();
