/* Setup the resource registrations for this module */

"use strict";

angular.module('pt.controllers').run([
        'resourceFactory',
        function (resourceFactory) {
            resourceFactory
                 .register('messages', '/messages/:id/:action', { id: '@id' }, {
                     reply: { method: 'POST', params: { action: 'Reply' } },
                     compose: { method: 'POST', params: { action: 'Compose' } },
                     searchThreads: { method: 'GET', params: { action: 'SearchThreads' } },
                     getThreads: { method: 'GET', isArray: true, params: { action: 'Threads' } },
                     getThreadDetails: { method: 'GET', params: { action: 'ThreadDetails' } },
                     getContacts: { method: 'GET', isArray: true, params: { action: 'Contacts' } },
                     getMessages: { method: 'GET', isArray: true, params: { action: 'Messages' } },
                     getUnreadMessageCount: { method: 'GET', params: { action: 'GetUnreadMessageCount' } },
                     totalUnreadMessageCount: { method: 'POST', params: { action: 'TotalUnreadMessageCount' } },
                     validateAttachment: { method: 'POST', params: { action: 'ValidateAttachment' } },
                     validateAttachments: { method: 'POST', params: { action: 'ValidateAttachments' } },
                     isMessagesSwitchedOff: { method: 'GET', params: { action: 'IsMessagesSwitchedOff' } }
                 });
        }
]);
