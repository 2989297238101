"use strict";
angular.module('pt.directives')

    .directive('tdFileSizeError', [
        function () {
            return {
                templateUrl: '/partials/common/directives/tdFileSizeError.html',
                replace: true,
                scope: {
                    files: '=',
                    hasExceededFileSize: '='
                },
                link: function (scope, element, attrs) {
                    scope.hasExceededFileSize = false;
                    scope.totalFilesSize = 0;
                    scope.maxAllowedFileSize = PTConfig.MAX_ALLOWED_FILE_SIZE;
                    function calculateAttachmentsSize(files) {
                        if (files && files.length > 0) {
                            scope.totalFilesSize = 0;
                            for (var i = 0; i < files.length; i++) {
                                scope.totalFilesSize = scope.totalFilesSize + files[i].size;
                            }

                            scope.totalFilesSize = Math.round((scope.totalFilesSize / 1000000));
                            scope.hasExceededFileSize = scope.totalFilesSize > scope.maxAllowedFileSize;
                        }
                    }

                    scope.$watchCollection('files', function (val) {
                        calculateAttachmentsSize(val);
                    });
                }
            };
        }
    ]);