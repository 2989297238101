"use strict";
(function(){
  angular.module('pt.directives')
    .directive("tdClickOutside", ["$log", function($log){
      var dir = {
        restrict:"A",
        scope:{
          tdClickOutside:"&"
        },
        link:function($scope, el, attrs){
         
          angular.element('html').on('touchstart', function(){
            $scope.$eval($scope.tdClickOutside);
            $scope.$apply();
          });

          angular.element(el).on('touchstart', function(e){
            e.stopPropagation();
          });

        }
      };
      return dir;
    }]);
})();