"use strict";
angular.module('pt.directives')

    .directive('tdShowPopoverForLargeWidth', ['usSpinnerService', '$timeout',

        function (usSpinnerService, $timeout) {
            return {
                templateUrl: '/partials/common/directives/tdShowPopoverForLargeWidth.html',
                scope: {
                    options: '=',
                    scopeHelper: '=',
                    isParentCollapsed: '=',
                    rowData: '@',
                    description: '='
                },
                link: function (scope, ele, attrs) {
                    scope.isOverFlowing = scope.description.length > 80;
                }
            };
        }
    ]);