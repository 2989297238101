"use strict";
angular.module('pt.directives')
    .directive('tdDatePicker', ['$window', '$filter',
        function ($window, $filter) {
            return {
                restrict: 'A',
                templateUrl: '/partials/common/directives/tdDatePicker.html',
                replace: true,
                scope: {
                    minDate: "=",
                    maxDate: "=",
                    selectedDate: "="
                },
                link: {
                    pre: function (scope) {
                        scope.dateOptions = {
                            formatYear: 'yy',
                            startingDay: 1
                        };

                        scope.altInputFormats = ['d!/M!/yyyy'];
                    },
                    post: function(scope, element, attrs) {

                        var datepicker = element;
                        scope.showWeeks = false;
                        scope.today = function() {
                            return scope.selectedDate;
                        };
                        scope.today();

                        scope.clear = function () {
                            scope.selectedDate = null;
                        };

                        scope.toggleMin = function() {
                            var date = scope.minDate ? scope.minDate : new Date(2000, 1, 1);
                            scope.minDateInternal = PTUtils.toUnspecifiedISOString(date, true);
                        };
                        scope.toggleMin();

                        scope.toggleMax = function() {
                            var date = scope.maxDate ? scope.maxDate : new Date(2100, 1, 1);
                            scope.maxDateInternal = PTUtils.toUnspecifiedISOString(date, true);
                        };
                        scope.toggleMax();

                        scope.open = function($event) {
                            $event.preventDefault();
                            $event.stopPropagation();

                            scope.opened = true;
                        };

                        scope.$watch('minDate', function(val) {
                            scope.toggleMin();
                        });

                        scope.$watch('maxDate', function(val) {
                            scope.toggleMax();
                        });
                        
                        //bug- TD-601
                        //issue in git - https://github.com/angular-ui/bootstrap/issues/3644
                        scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate', 'dd/MM/yyyy'];
                        scope.format = scope.formats[4];
                        

                        //bug- TD-616
                        //issue in git - https://github.com/angular-ui/bootstrap/issues/3617
                        scope.$watch('selectedDate', function (val) {
                            if (val) {
                                if (!scope.checkValidDate(val)) {
                                    if (scope.minDate && val < scope.minDate) {
                                        scope.dateChanged(scope.minDate);
                                    } else if (val > scope.maxDate) {
                                        scope.dateChanged(scope.maxDate);
                                    } else {
                                    }
                                } else {
                                    //scope.dateChanged(scope.scope.minDate);
                                }
                            }
                        });

                        scope.checkValidDate = function (value) {
                            if (value !== undefined && value !== null && value !== '') {
                                if (value instanceof Date) {
                                    var date = Date.parse(value);
                                    if (date < 0) {
                                        return true;
                                    } else {
                                        if (isNaN(date)) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }
                                } else {
                                    return false;
                                }
                            } else {
                                return true;
                            }
                        };

                        scope.dateChanged = function(date) {
                            $window.setTimeout(function () {
                                scope.selectedDate = date;
                            }, 200);
                        };
                    }
                }
            };
        }
    ]);
