(function() {
    'use strict';

    angular.module('pt.directives')
        .directive('tdUploader', directiveFn)
        .directive('tdUploadPreviewer', dTdUploadPreviewItem);

    directiveFn.$inject = ['$q', 'shell', '$timeout', 'resourceFactory'];
    function directiveFn($q, shell, $timeout, resourceFactory) {
        var directive = {
            restrict:'E',
            scope:{
                files:'=',
                acceptedFiles:'@'
            },
            replace:true,
            template: 
                '<div class="td-uploader">' +
                    '<div td-loading="vm.tdUIsLoading"></div>' +
                    '<td-file-add files="vm.files"></td-file-add>' +
                    '<div class="td-upl-preview" ng-if="vm.files.length > 0">' +
                        '<td-upload-previewer file="file" remove="removeFile(file, $index)" ng-repeat="file in vm.files"></td-upload-previewer>' +
                    '</div>' +
                '</div>',
            compile:compileFn
        };
        return directive;

        function compileFn(tEle, tAttrs, transclude) {
            return postLinkFn;

            function postLinkFn(scope, elem, attr) {
                var messagesApi = resourceFactory.create('messages');
                scope.vm = {
                    files: [],
                    tdUIsLoading:false
                };

                scope.removeFile = removeFileFn;

                scope.$on('tdFileAdd:FileAdded', function (t, files) {
                    var validateAttachmentModels = buildValidateAttachmentModels(files);

                    messagesApi.validateAttachments(validateAttachmentModels, function success(val) {
                        handleFiles(files);
                    }, function (error) {
                        shell.flash.error(error.data);
                    });
                });

                function buildValidateAttachmentModels(files) {
                    var validateAttachmentModels = [];
                    _.each(files, function (file) {
                        validateAttachmentModels.push({ Size: file.size, Name: file.name + file.extension, MimeType: file.type });
                    });
                    return validateAttachmentModels;
                }

                function validateFile(file){
                    if(!scope.acceptedFiles) return true;

                    var fileRegex = new RegExp(scope.acceptedFiles, 'gi');
                    return fileRegex.test(file.extension);
                }

                function handleFiles(files){
                    var allQ = [];
                    scope.vm.tdUIsLoading = true;

                    _.each(files, function(file){
                        if(validateFile(file))
                            allQ.push(file.readAsync());
                        else{
                            shell.flash.error('One of the uploaded files have incorrect filetype.');
                            return;
                        }
                    });

                    $q.all(allQ).then(function(rFiles){
                        scope.vm.tdUIsLoading = false;
                        scope.vm.files = scope.vm.files.concat(rFiles);
                    });
                }

                function removeFileFn(file, indx){
                    var idx = scope.vm.files.indexOf(file);
                    scope.vm.files = 
                        scope.vm.files.slice(0, idx)
                        .concat(scope.vm.files.slice(idx+1));
                }

                scope.$watchCollection('vm.files', function(val){
                    scope.files = val;
                });
            }
        }
    }
    
    dTdUploadPreviewItem.$inject = [];
    function dTdUploadPreviewItem(){
        return {
            restrict:'E',
            scope:{
                file:'=',
                remove:'&'
            },
            replace:true,
            controller:ctrlUplPrev,
            template: 
                '<div class="td-upl-preview-item">' +
                    '<div class="media">' +
                        '<div class="media-left">' +
                            '<img src="{{file.data}}" class="media-thumbnail" ng-if="isImage" />' +
                            '<span class="icon-file-text media-thumbnail" ng-if="!isImage"></span>' +
                        '</div>' +
                        '<div class="media-body media-middle">' +
                            '<span class="file-details">{{file.name}} - <span class="fileSize">({{ file.size | tdFileSize}})</span><span>' +
                            '<a href="#" class="remove" ng-click="remove()"><i class="icon-times" /></a>' +
                        '</div>' +
                    '</div>' +
                '</div>',
        };
    }
    
    ctrlUplPrev.$inject = ['$scope'];
    function ctrlUplPrev($scope){
        $scope.isImage = /(svg|img|jpg|jpeg|png)/gi.test($scope.file.type);
    }

})();
