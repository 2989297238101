'use strict';

/* Controllers */
(function () {
    angular.module('pt.controllers')
        .controller('rentHistoryCtrl', ['$scope', '$rootScope', '$http', 'resourceFactory', '$window', '$state', '$intercom', '$stateParams',
            function ($scope, $rootScope, $http, resourceFactory, $window, $state, $intercom, $stateParams) {

                var rentApi = resourceFactory.create('owners');
                var rent = rentApi.rentHistory().$promise;

                rent.then(function (data) {
                    $scope.rentHistory = data;
                    setGraph();
                });

                function setGraph() {
                    for (var i = 0; i < $scope.rentHistory.length; i++) {
                        var localData = [];
                        var localLabels = [];
                        for (var j = 0; j < $scope.rentHistory[i].RentHistory.length; j++) {
                            localData.push($scope.rentHistory[i].RentHistory[j].WeeklyRent);
                            var formatDate = moment(new Date($scope.rentHistory[i].RentHistory[j].ChangeDate.toDateString())).format('DD/MM/YYYY');
                            localLabels.push(formatDate);
                        }

                        $scope.rentHistory[i].lineData = {
                            labels: localLabels,
                            datasets: [
                                {
                                    fillColor: ["#0f5f87"],
                                    strokeColor: ["#0f5f87"],
                                    pointColor: ["#0f5f87"],
                                    pointStrokeColor: ["#fff"],
                                    pointDotRadius: 4,
                                    data: localData
                                }
                            ]
                        };
                    }
                }

                $scope.lineOptions = {
                    responsive: true,
                    responsiveWindowInitialWidth: true,
                    inGraphDataShow: true,
                    annotateDisplay: true,
                    annotateLabel: "<%=v2+': $'+v3%>",
                    yAxisMinimumInterval: 250,
                    yAxisMaximumInterval: 1000,
                    roundNumber: -2,
                    graphMin: 0,
                    animationLeftToRight: true,
                    animationEasing: "linear",
                    drawXScaleLine: [{ position: "0", lineWidth: 1 }],
                    scaleLabel: "$<%=value%>",
                    inGraphDataTmpl: "$<%=v3%>",
                    datasetFill: false
                };
            }]);
})();
