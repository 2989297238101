'use strict';

/* Controllers */
(function () {
    angular.module('pt.controllers')
        .controller('composeCtrl', ['$rootScope', '$scope', '$sce', '$http', 'flash', 'resourceFactory', '$stateParams', '$state', '$q', '$interval', 'usSpinnerService', '$window', '$timeout',
         'shell', 
         '$location', 
         '$intercom',
         function ($rootScope, $scope, $sce, $http, flash, resourceFactory, $stateParams, $state, $q, $interval, usSpinnerService, $window, $timeout, shell, $location, $intercom) {
            var messagesApi = resourceFactory.create('messages');
            var profileApi = resourceFactory.create('profile');
            $scope.composeModel = null;
            $scope.singleAgent = true;
            $scope.singleProfile = true;
            $scope.hasSubjectError = false;
            $scope.hasBodyError = false;
            $scope.hasAddressToError = false;
            $scope.hasSelectedProfileError = false;
            $scope.composeSpinnerStatus = false;
            $scope.hasExceededFileSize = false;
            $scope.isSending = false;
            $scope.doBlur = false;
            var tinymce = $window.tinymce;
            $scope.tinymceModel = {};
            $scope.characterCount = 0;
            $scope.maxCount = 4000;

            $scope.popover = {
                scopeHelper: $scope,
                options: {
                    html: true,
                    container: '#cancelButton',
                    content: '<div>Are you sure you want to cancel and discard this message?</div>' +
                                    '<div class="spacer10"></div>' +
                                '<div class="clearfix"><div class="pull-right"><button type="button" class="btn secondary" ng-click="closePopover($event)"><b>No</b></button>&nbsp;&nbsp;&nbsp;&nbsp;<button type="button" class="btn primary" ng-click="confirmCancel()">Yes</button></div></div>',
                    placement: 'top'
                },
            };

            $scope.closePopover = function (event) {
                $scope.popover.options.dismissPopover();
                event.stopPropagation();
            };

            $scope.tinymceOptions = {
                toolbar: "undo redo | bold italic | alignleft alignjustify | bullist numlist | link unlink",
                plugins: "link",
                target_list: false,
                link_title: false,
                extended_valid_elements: 'a[href|target=_blank]',
                relative_urls: false,
                menubar: false,
                statusbar: false,
                content_css: "/dist/css/tinymce.css",
                init_instance_callback: function (ed) {
                    ed.on('click', function (e) {
                        ed.focus();
                        $scope.setCharaterCount();
                    });

                    ed.on('KeyUp', function (e) {
                        $scope.setCharaterCount();
                    });
                }
            };

            $scope.setCharaterCount = function () {
                var tinyContent = tinymce.activeEditor.getContent({ format: 'text' });
                $scope.characterCount = tinyContent.length === 1 && /\s/g.test(tinyContent) && tinyContent !== '' ? 0 : tinyContent.length;
                $timeout(function () {
                    $scope.$apply();
                });
            };

            tinymce.init($scope.tinymceOptions);

            $scope.getAttachmentUrl = function (rawUrl) {
                return $sce.trustAsResourceUrl(rawUrl);
            };

            function isMessagesSwitchedOff() {
                messagesApi.isMessagesSwitchedOff({}, function (data) {
                    $scope.isMessagesSwitchedOff = data.IsMessagesSwitchedOff;
                });
            }

            isMessagesSwitchedOff();

            /////////// Compose new message functions.
            $scope.composeOwnerInternal = function (profileReference) {
                
                $scope.composeModel = {
                    message: "",
                    attachments: [],
                    isOwnerMessage: true
                };
                $scope.firstAttempt = null;
                $scope.hasBodyError = false;
                $scope.hasSubjectError = false;

                $scope.getProfiles(function (d) {
                    return d.ProfileType === 'Owner' || d.ProfileType === 'Management';
                }, 'Management', profileReference);

            };

            $scope.composeTenancyInternal = function (profileReference) {
                $scope.composeModel = {
                    message: "",
                    attachments: [],
                    isOwnerMessage: false
                };
                $scope.firstAttempt = null;
                $scope.hasBodyError = false;
                $scope.hasSubjectError = false;

                $scope.getProfiles(function (d) {
                    return d.ProfileType === 'Tenancy';
                }, 'Tenancy', profileReference);
            };

            $scope.getProfiles = function (profileFilter, profileType, profileReference) {
                $scope.composeSpinnerStatus = true;
                profileApi.profiles({ profileType: '' }, function (data) {
                    var filteredList = data.filter(profileFilter);
                    $scope.composeModel.profiles = filteredList;
                    //$scope.setEntity(data);
                    if ($scope.composeModel.profiles.length > 0) {
                        for (var i = 0; i < $scope.composeModel.profiles.length; i++) {
                            
                            if (!profileReference) {
                                if ($scope.composeModel.profiles.length == 1) {
                                    $scope.composeModel.selectedProfile = $scope.composeModel.profiles[i];
                                    break;
                                }
                            } else {
                                if ($scope.composeModel.profiles[i].ProfileType === profileType && $scope.composeModel.profiles[i].ProfileReference === profileReference) {
                                    $scope.composeModel.selectedProfile = $scope.composeModel.profiles[i];
                                    break;
                                }
                            }
                        }
                    }

                    // Stopping the spinner at the time before showing the dropdown content
                    $scope.composeSpinnerStatus = false;

                    if ($scope.composeModel.profiles.length == 1) {
                        $scope.singleProfile = true;
                    }

                    if ($scope.composeModel.profiles.length > 1) {
                        $scope.singleProfile = false;
                    }
                }, function (response) {
                    $scope.composeSpinnerStatus = false;
                });
            };

            $scope.profileSelected = function (profile) {
                $scope.composeModel.selectedProfile = profile;
            };

            $scope.agentSelected = function (agent) {
                $scope.composeModel.addressTo = agent;
            };

            $scope.loadSelectedProfileAgentDetails = function (profile) {
                $scope.composeSpinnerStatus = true;
                $scope.composeModel.addressTo = null;

                if (!profile) return;

                var paraModel = {
                    ProfileType: profile.ProfileType,
                    ProfileReference: profile.ProfileReference,
                    CompanyReference: profile.CompanyReference,
                    ContactReference: profile.ContactReference
                };

                // call PT for agent details.
                profileApi.getAgent(paraModel, function (data) {
                    // if 1 agent... then use addressTo.
                    // if multiple, then drop down combo.
                    $scope.composeSpinnerStatus = false;
                    var agents = _.uniq(data.Agents, function (agent) {
                        return agent.CompanyReference && agent.DisplayName;
                    });

                    if (agents.length === 1) {
                        $scope.composeModel.addressTo = agents[0];
                        $scope.singleAgent = true;
                    }
                    else {
                        $scope.singleAgent = false;
                        $scope.composeModel.agentList = agents;
                    }
                });
            };

            $scope.$watch('composeModel.selectedProfile', function (val) {
                if ($scope.composeModel !== null) {
                    $scope.loadSelectedProfileAgentDetails(val);
                }

                if (val) {
                    $scope.canSendMessage();
                }
            });

            $scope.$watch('composeModel.addressTo', function (val) {
                if (val) {
                    $scope.canSendMessage();
                }
            });

            $scope.$watch('composeModel.subject', function (val) {
                $scope.canSendMessage();
            });

            $scope.$watch('composeModel.message', function (val) {
                $scope.canSendMessage();
            });

            $scope.canSendMessage = function () {
                if ($scope.firstAttempt && $scope.composeModel) {
                    
                    if (!$scope.composeModel.addressTo) {
                        $scope.hasAddressToError = true;
                    }
                    else {
                        $scope.hasAddressToError = false;
                    }

                    if (!$scope.composeModel.selectedProfile) {
                        $scope.hasSelectedProfileError = true;
                    }
                    else {
                        $scope.hasSelectedProfileError = false;
                    }

                    if ($scope.composeModel.message) {
                        $scope.hasBodyError = checkContentEmpty($scope.composeModel.message) === 0 ? true : false;
                    }
                    else {
                        $scope.hasBodyError = true;
                    }
                    if ($scope.composeModel.subject) {
                        $scope.hasSubjectError = false;
                    }
                    else {
                        $scope.hasSubjectError = true;
                    }

                    if ( $scope.hasSubjectError || $scope.hasBodyError ||
                         $scope.hasSelectedProfileError || $scope.hasAddressToError || $scope.hasExceededFileSize )
                    {
                        return false;
                    }
                }
                return true;
            };

            function checkContentEmpty(message) {
                var local = message;
                var replaced = local.replace(/&nbsp;|\s|<p>|<\/p>/gm, '');
                return replaced.length;
            }

            $scope.send = function () {
                $scope.firstAttempt = true;

                if (!$scope.canSendMessage()) {
                    flash.error('There were errors. Please correct these errors below.');
                    return;
                }

                if ($scope.composeModel.selectedProfile && $scope.composeModel.addressTo) {

                    $q.all($scope.composeModel.attachments.map(function (item) { return item.readAsync(); })).then(function (vals) {
                        var param = {
                            CompanyReference: $scope.composeModel.selectedProfile.CompanyReference,
                            ContactReference: $scope.composeModel.selectedProfile.ContactReference,
                            ProfileType: $scope.composeModel.selectedProfile.ProfileType,
                            ProfileReference: $scope.composeModel.selectedProfile.ProfileReference,
                            Subject: $scope.composeModel.subject,
                            Message: $scope.composeModel.message,
                            AddressTo: $scope.composeModel.addressTo,
                            Attachments: vals.map(function (a) {
                                return {
                                    Name: a.name,
                                    MimeType: a.type,
                                    Data: a.data.substring(a.data.indexOf(',') + 1)
                                };
                            })
                        };
                        $scope.isSending = true;
                        messagesApi.compose(param, function success(val) {
                            $window.Intercom('trackEvent', 'compose-new-message');
                            if ($window.location.hash) {
                                $window.history.back();
                            }
                            else {
                                var profileType;
                                if ($scope.composeModel.selectedProfile.ProfileType === 'Tenancy') {
                                    profileType = 'Tenancy';
                                }
                                else {
                                    profileType = 'Owner';
                                }
                                $scope.composeModel = null;
                                $scope.firstAttempt = null;
                                $scope.hasBodyError = false;
                                $scope.hasSubjectError = false;
                                $scope.hasAddressToError = false;
                                $scope.hasSelectedProfileError = false;
                                
                                if (profileType === 'Tenancy') {
                                    $state.go('shell.messages', {
                                        profileType: 'tenant'
                                    });
                                }
                                else {
                                    $state.go('shell.messages', {
                                        profileType: 'owner'
                                    });
                                }
                            }
                            $scope.isSending = false;
                            $scope.characterCount = 0;
                            flash.success('Message Successfully Sent');
                        }, function (error) {
                            $scope.isSending = false;
                            flash.error('Sorry, there was a problem sending your message. Please refresh the page and try again.');
                        });
                    });
                }
            };

            $scope.addComposeAttachment = function (file) {
                if (!$scope.composeModel || !$scope.composeModel.attachments) return;

                var param = {
                    Name: file.name,
                    MimeType: file.type,
                    Size: file.size
                };

                messagesApi.validateAttachment(param, function success(val) {
                    $scope.composeModel.attachments.push(file);
                }, function (error) {
                    if (error.status === 400) {
                        flash.error(error.data);
                    }
                });
            };

            $scope.removeComposeAttachment = function (index) {
                if (!$scope.composeModel || !$scope.composeModel.attachments) return;
                $scope.composeModel.attachments.splice(index, 1);
            };

            $scope.confirmCancel = function () {
                
                // Reset Compose model data
                $scope.composeModel = null;
                $scope.firstAttempt = undefined;
                $scope.hasBodyError = false;
                $scope.hasSubjectError = false;
                $scope.hasAddressToError = false;
                $scope.hasSelectedProfileError = false;

                $window.history.back();
            };

            if ($stateParams.managementId) {
                $scope.composeOwnerInternal($stateParams.managementId);
            } else {
                if ($stateParams.profileType === 'owner') {
                    $scope.composeOwnerInternal();
                }
            }

            if ($stateParams.tenancyId) {
                $scope.composeTenancyInternal($stateParams.tenancyId);
            } else {
                if ($stateParams.profileType === 'tenant') {
                    $scope.composeTenancyInternal();
                }
            }
            
            function checkFormDirty() {
                if (tinymce.activeEditor) {
                    var text = tinymce.activeEditor.getContent({ format: 'text' });
                    $scope.isContentEmpty = !/\S/.test(text);
                    return !!($scope.composeModel && ($scope.composeModel.subject || !$scope.isContentEmpty || ($scope.composeModel.selectedProfile && $scope.composeModel.selectedProfile.ProfileName) || ($scope.composeModel.attachments && $scope.composeModel.attachments.length > 0)));
                } else {
                    return false;
                }
            }

        var onRouteChangeOff = $rootScope.$on('$stateChangeStart', routeChange);
            function routeChange(event, next, current) {
                var isFormDirty = checkFormDirty();
                if (isFormDirty) {
                    var buttonDefinition = [{ result: 'cancel', label: 'Cancel' }, { result: 'ok', label: 'Discard Message', cssClass: 'primary' }];
                    var message = '<p>Your message has not been sent.<br/>Are you sure you want to leave this page and discard your changes?<p>';
                    shell.confirmBox('Confirm Cancel Message', message, buttonDefinition, true)
                    .result.then(function (result) {
                        if (result == "ok") {
                            onRouteChangeOff();
                            if (next.name === 'shell.manageInspections') {
                                $state.go(next.name, { profileType: 'tenant', inspectionId: $rootScope.inspectionId });
                            } else {
                                $state.go(next.name, { profileType: $stateParams.profileType });
                            }
                        }
                    });

                    event.preventDefault();
                    return;
                } else {
                    onRouteChangeOff();
                }
            }
        }]);
})();
