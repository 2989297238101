'use strict';
angular.module('pt.directives').
  directive('alertFlash', ['$rootScope', '$window', function ($rootScope, $window) {
      return {
          link: function (scope, element, attrs) {
              $rootScope.$on('flash', function (msg, flash) {                  
                  if (flash.level == 'success') {
                      element
                          .show()
                          .html('<div class="alert alert-success animated zoomInDown">' +
                              '<div class="text-left message">' +
                              '<table class="alert-table"><tr>' + 
                              '<td><div class="pull-left icon-container"><i class="icon-tick1"></i></div></td><td valign="middle" height="100%"> ' + 
                              flash.message + '</td>' + 
                              '<td><button type="button" class="close" data-dismiss="alert">&times;</button></td></tr></table></div></div>');
                  } else if (flash.level == 'info') {
                      element
                          .show()
                          .html('<div class="alert alert-info animated zoomInDown">' +
                              '<div class="text-left message">' +
                              '<table class="alert-table"><tr>' + 
                              '<td><div class="pull-left icon-container"><i class="icon-warning"></i></div></td><td valign="middle" height="100%">' +
                              flash.message + '</td>' + 
                              '<td><button type="button" class="close" data-dismiss="alert">&times;</button></td></tr></table></div></div>');
                  } else if (flash.level == 'error') {
                      element
                          .show()
                          .html('<div class="alert alert-error animated shake">' +
                              '<div class="text-left message">' +
                              '<table class="alert-table"><tr>' + 
                              '<td><div class="pull-left icon-container"><i class="icon-delete"></i></div></td><td valign="middle" height="100%">' +
                              flash.message + '</td> ' + 
                              '<td><button type="button" class="close" data-dismiss="alert">&times;</button></td></tr></table></div></div>');
                  }

                  $window.setTimeout(function () {
                      element
                          .fadeOut();
                  }, 3000);
              });
          }
      };
  }]);
