/* Setup the resource registrations for this module */

"use strict";

angular.module('pt.controllers').run([
        'resourceFactory',
        function (resourceFactory) {
            resourceFactory
                .register('account', '/account/:action', {}, {
                    signup: { method: 'POST' },
                    get: { method: 'GET' },
                    update: { method: 'PUT' },
                    validateEmail: { method: 'POST', params: { action: 'validateEmail' } },
                    resetPassword: { method: 'POST', params: { action: 'resetPassword' } },
                    login: { method: 'POST', params: { action: 'login' } },
                    logout: { method: 'POST', params: { action: 'logout' } },
                })
                .register('profile', '/profile/:action', {}, {
                    get: { method: 'GET' },
                    profiles: { method: 'GET', isArray: true, params: { action: 'profiles' } },
                    getAgent: { method: 'POST', params: { action: 'getAgent' } }
                })
                .register('dashboard', '/dashboard/:action', {}, {
                    getIncomeExpenseGraph: { method: 'GET', isArray: true, params: { action: 'IncomeExpenseGraph' } }
                })
                .register('utility', '/utility/:action', {}, {
                    getToken: { method: 'GET', params: { action: 'getToken' } },
                })
                .register('receipts', '/receipts/:CompanyReference/:ContactReference/:ReceiptReference/:action', { CompanyReference: '@CompanyReference', ContactReference: '@ContactReference', ReceiptReference: '@ReceiptReference' }, {

                })
                .register('tenancyInvoices', '/invoices/TenancyInvoices/:CompanyReference/:ContactReference/:InvoiceReference/:action', { CompanyReference: '@CompanyReference', ContactReference: '@ContactReference', InvoiceReference: '@InvoiceReference' }, {

                })
                .register('properties', '/properties/:CompanyReference/:ContactReference/:PropertyReference/:action', { CompanyReference: '@CompanyReference', ContactReference: '@ContactReference', PropertyReference: '@PropertyReference' }, {
                })
                .register('companyFeature', '/companyFeature/:CompanyReference/:ContactReference/:action', { CompanyReference: '@CompanyReference', ContactReference: '@ContactReference'}, {
                    get: { method: 'GET', isArray: true, params: { action: 'getCompanyFeatureList' } }
                });
        }
]);
