(function() {
    'use strict';

    angular.module('pt.directives')
        .directive('tdCollapsibleCard', directiveFn);

    directiveFn.$inject = [];
    function directiveFn() {
        var directive = {
            restrict:'E',
            scope: {
                isCollapse: '=',
                headingText: '@',
                showStatus: '=',
                statusColor: '@',
                isArchived: '=',
                currentStatus: '='
            },
            replace:true,
            transclude:true,
            templateUrl: '/partials/common/directives/tdCollapsibleCard.html',
            compile:compileFn
        };
        return directive;

        function compileFn(tEle, tAttrs, transclude) {
            return postLinkFn;

            function postLinkFn(scope, elem, attr) {

                scope.getBgImgObj = function (item) {
                    //return { 'background': "linear-gradient(to right, #f6b158 0%, #f6b158 1%, #ccc 1%, #ccc 100%)" };
                    return { 'background-color': "#f6b158" };
                };

                function setColorclass(color) {
                    switch (color) {
                    case 'yellow':
                        return 'yellow-bg';
                    case 'green':
                        return 'green-bg';
                    case 'blue':
                        return 'blue-bg';
                    case 'dark blue':
                        return 'dark-blue-bg';
                    case 'orange':
                        return 'orange-bg';
                    case 'grey':
                        return 'dark-grey-bg';
                    default:
                        return 'blue-bg';
                    }
                }

                scope.currentColorClass = setColorclass(scope.statusColor);

                scope.popover = {
                    scopeHelper: scope,
                    options: {
                        html: true,
                        trigger: 'hover',
                        container: '#colorContent',
                        content: '<div class="pull-left"><div class="' + scope.currentColorClass + ' pull-left icon-square"></div><div class="pull-left square-label">{{currentStatus}}</div></div>',
                        placement: 'right'
                    },
                };
            }
        }
    }

})();
