(function() {
    'use strict';

    angular.module('pt.directives')
        .directive('tdMrViewPanel', directiveFn);

    directiveFn.$inject = ['$stateParams'];
    function directiveFn($stateParams) {
        var directive = {
            restrict:'E',
            scope:{
                mreq:'='
            },
            replace:true,
            templateUrl: '/dist/js/components/maintenanceRequests/tdMRViewPanel.template.html',
            compile:compileFn
        };
        return directive;

        function compileFn(tEle, tAttrs, transclude) {
            return postLinkFn;

            function postLinkFn(scope, elem, attr) {
                var isCancelled = /cancel/gi.test(scope.mreq.DisplayStatus);
                scope.isTenancy = /tenan/gi.test(attr.profileType);
                
                scope.vm = {
                    statusDateLabel: isCancelled ?'Cancellation Date:' : 'Completed Date:',
                    descHeadingLabel: isCancelled ?'Cancellation Description:' : 'Maintenance Description',
                    detailsText: isCancelled ? scope.mreq.CancellationReason : scope.mreq.Details,
                };
            }
        }
    }

})();
