"use strict";

/*
    bind template to popover
*/

angular.module('pt.directives')
.directive('tdPopover', [
      '$compile',
      function ($compile) {
          return {
              scope: {
                  tdPopover: '=',
                  scopeHelper: '='                  
              },

              link: function (scope, element, attrs) {
                  var options;
                  var visible = false;
                  var touched = false;

                  var closeTouchPopOver = function(){
                    element.popover('hide');
                    visible = false;
                    touched = false;
                  };

                  function onEnter(e) {
                      if (!visible) {
                          element.popover('show');
                          visible = true;
                          touched = false;
                      }
                  }

                  function onLeave(e) {
                      if (visible && !touched) {
                          element.popover('hide');
                      }
                  }

                  function onTouchStart(e) {
                      if (visible && touched) {
                          closeTouchPopOver();
                      } else {
                          element.popover('show');
                          touched = true;
                          visible = true;
                          e.stopPropagation();
                      }
                  }

                  function onClick(e) {
                      if (touched) {
                          // do nothing. already handled by onTouchStart
                      } else if (visible) {
                          element.popover('hide');
                          visible = false;
                      } else {
                          element.popover('show');
                          visible = true;
                      }
                  }

                  function onHidden(e) {
                      visible = false;
                      touched = false;
                  }

                  angular.element('html').on('touchstart', function(e){
                    if(visible && touched){
                      closeTouchPopOver();
                    }
                  });

                  scope.$watch('tdPopover', function () {
                      var tpl = '<div class="popover"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"><p></p></div></div>';

                      // this is a work-around for a bootstrap popover bug see : https://github.com/twbs/bootstrap/issues/13696
                      if (scope.tdPopover && scope.tdPopover.placement && (scope.tdPopover.placement === 'top')) {
                          tpl = '<div class="popover"><div class="arrow" style="top:100%!important"></div><h3 class="popover-title"></h3><div class="popover-content"><p></p></div></div>';
                      }

                      options = {
                          trigger: scope.tdPopover && scope.tdPopover.trigger ? scope.tdPopover.trigger : 'manual',
                          html: scope.tdPopover && scope.tdPopover.html ? scope.tdPopover.html : false,
                          placement: scope.tdPopover && scope.tdPopover.placement ? scope.tdPopover.placement : 'top',
                          content: function () {
                              var compliedContent;
                              scope.$apply(function () {
                                  compliedContent = $compile(scope.tdPopover.content)(scope.scopeHelper);
                              });
                              return compliedContent;
                          },
                          template: tpl
                      };
                       
                      if (scope.tdPopover && scope.tdPopover.container) {
                          options.container = scope.tdPopover.container;
                      }
                    
                      if (scope.tdPopover && (!scope.tdPopover.trigger || scope.tdPopover.trigger == 'manual')) {
                          // show popover using the following rules:
                          // - Popover shows on hover or click
                          // - If shown by hover, then click or move mouse away will dismiss it
                          // - If shown by touch, then only touch will dismiss it
                          // This should cover both desktop and mobile cases without making any assumptions as to the device capabilities.
                          element.on('mouseenter', onEnter);
                          element.on('mouseleave', onLeave);
                          element.on('touchstart', onTouchStart);
                          element.on('click', onClick);
                          element.on('hide.bs.popover', onHidden);
                      }

                      element.popover(options);

                      if (scope.tdPopover) {
                          scope.tdPopover.dismissPopover = function () {
                              element.popover('hide');
                          };
                      }
                  });
              } 
          };
      }
]);