"use strict";
angular.module('pt.directives')

    .directive('tdWorkFlowStatus', ['usSpinnerService', '$timeout',

        function (usSpinnerService, $timeout) {
            return {
                templateUrl: '/partials/common/directives/tdWorkFlowStatus.html',
                scope: {
                    statusList: '=',
                    currentStatus: '='
                },
                link: function (scope, ele, attrs) {
                    for (var i = 0; i < scope.statusList.length; i++) {
                        if (scope.currentStatus && scope.currentStatus.toLowerCase() === scope.statusList[i].status && i > 0) {
                            scope.statusList = setStatus(i, scope.statusList);
                        }
                    }

                    function setStatus(len, list) {
                        for (var j = len ; j >= 0; j--) {
                            if (len < list.length) {
                                list[j].isEmpty = false;
                                if (j > 0) {
                                    list[j - 1].color = 'grey';
                                }
                            }
                        }

                        return list;
                    }
                }
            };
        }
    ]);