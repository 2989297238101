"use strict";
angular.module('pt.directives')

    .directive('tdLoading', ['usSpinnerService', '$timeout',

        function (usSpinnerService, $timeout) {
            var lastSpinnerKey = 1;

            return {
                templateUrl: '/partials/common/directives/tdLoading.html',
                scope: {
                    tdLoading:'='
                },
                link: function (scope, ele, attrs) {
                    var id = lastSpinnerKey++;
                    scope.spinnerKey = 'tdloading-' + id;
                    scope.showBlocker = attrs.showBlocker ? attrs.showBlocker : true;
                    scope.showSpinner = attrs.showSpinner ? attrs.showSpinner : true;
                }
            };
        }
    ]);