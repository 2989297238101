(function(){
    /* Setup the resource registrations for this module */
    "use strict";

    angular.module('pt.controllers').run([
        'resourceFactory',
        function (resourceFactory) {
            resourceFactory
                .register('maintenanceRequest', '/MaintenanceRequests/:CompanyReference/:ContactReference/:action',
                    { CompanyReference: '@CompanyReference', ContactReference:'@ContactReference' }, 
                    {
                        create: { method: 'POST', params: { action: '' } },
                    })
                .register('viewContact', '/MaintenanceRequests/:CompanyReference/:MaintenanceRequestReference/:action',
                    { CompanyReference: '@CompanyReference', MaintenanceRequestReference: '@MaintenanceRequestReference' },
                    {
                        getOwnerContact: { method: 'GET', params: { action: 'GetOwnerContact' } },
                    })
                .register('viewMaintenanceRequest', '/MaintenanceRequests/:CompanyReference/:ContactReference/:MaintenanceRequestReference/:action',
                    { CompanyReference: '@CompanyReference', ContactReference: '@ContactReference', MaintenanceRequestReference: '@MaintenanceRequestReference' },
                    {
                        getMaintenanceRequest: { method: 'GET', params: { action: '' } },
                        uploadMaintenanceJobAttachment: { method: 'POST', params: { action: 'UploadJobAttachments' } },
                        approveRequest: { method: 'POST', params: { action: 'ApproveRequest' } },
                    });

        }
    ]);

})();