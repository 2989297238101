(function() {
    'use strict';

    angular.module('pt.directives')
        .directive('tdMrViewCollection', directiveFn);

    directiveFn.$inject = [];
    function directiveFn() {
        var directive = {
            restrict:'E',
            scope:{
                mrColl:'='
            },
            replace:true,
            templateUrl: '/dist/js/components/maintenanceRequests/tdMRViewCollection.template.html',
            compile:compileFn
        };
        return directive;

        function compileFn(tEle, tAttrs, transclude) {
            return postLinkFn;

            function postLinkFn(scope, elem, attr) {
                scope.vm = {
                    MRColl: scope.mrColl,
                    collHeader: attr.header,
                    profileType: attr.profileType
                };
            }
        }
    }

})();
