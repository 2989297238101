(function() {
    'use strict';

    angular.module('pt.filters')
        .filter('tdFileSize', filterFn);

    filterFn.$inject = [];
    function filterFn() {
        return function(text) {
            return filesize(text, {base:10});
        };
    }

})();
