/* Setup the resource registrations for this module */
"use strict";
angular.module('pt.controllers').run([
        'resourceFactory',
        function (resourceFactory) {
            resourceFactory
            .register('ownerstatements',
                      '/statements/OwnerStatements/:CompanyReference/:ContactReference/:OwnerReference/:action',
                      { CompanyReference: '@CompanyReference', ContactReference: '@ContactReference', OwnerReference: '@OwnerReference' },
                      {})
            .register('incomeexpenditurestatements',
                      '/statements/IncomeExpenditureStatements/:CompanyReference/:ContactReference/:OwnerReference/:action',
                      { CompanyReference: '@CompanyReference', ContactReference: '@ContactReference', OwnerReference: '@OwnerReference' },
                      {})
            .register('owners',
                          '/owners/:CompanyReference/:ContactReference/:OwnerReference/:action',
                          {   CompanyReference: '@CompanyReference', ContactReference: '@ContactReference', OwnerReference: '@OwnerReference' }, {
                              ledgerReport: { method: 'GET', params: { action: 'LedgerReport' } },
                              rentHistory: { method: 'GET', isArray: true, params: { action: 'RentChangeHistory' } },
                              maintenanceRequests: { method: 'GET', isArray: true, params: { action: 'maintenanceRequests' } }
                          });
        }
]);
