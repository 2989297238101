'use strict';

/* Controllers */
(function () {
    angular.module('pt.controllers')
        .controller('threadViewCtrl', ['$rootScope', '$scope', '$sce', '$http', 'flash', 'resourceFactory', '$stateParams', '$state', '$q', '$interval', 'usSpinnerService', '$window', '$timeout',
            'shell', 
            '$location', 
            '$intercom',
            function ($rootScope, $scope, $sce, $http, flash, resourceFactory, $stateParams, $state, $q, $interval, usSpinnerService, $window, $timeout, shell, $location, $intercom) {
            var messagesApi = resourceFactory.create('messages');
            var profileApi = resourceFactory.create('profile');

            $scope.model = {};
            $scope.contactModel = null;
            $scope.showContent = false;
            $scope.isContactsLoaded = false;
            $scope.isMessagesLoaded = false;
            $scope.model.tenantUnreadCount = "";
            $scope.model.ownerUnreadCount = "";
            $scope.model.totalUnreadCount = "";
            $scope.isOwner = false;
            $scope.isTenant = false;
            $scope.showOwner = $stateParams.profileType === 'owner' ? true : false;
            $scope.showTenant = $stateParams.profileType === 'owner' ? false : true;
            $scope.progressCount = 0;
            $scope.isOwnerTabActive = $stateParams.profileType === 'owner' ? true : false;
            $scope.isMessagesLoading = false;
            $scope.hasExceededFileSize = false;
            $scope.characterCount = 0;
            $scope.maxCount = 4000;

            var localThreadId = '';


            
            $scope.status = {
                isThreadsOpen: false
            };

            $scope.contactsSpinnerStatus = false;
            $scope.inboxSpinnerStatus = false;
            $scope.threadsSpinnerStatus = false;
            $scope.converseSpinnerStatus = false;
            $scope.isSending = false;
            $scope.doBlur = false;

            $scope.pageSpinnerStatus = function () {
                return $scope.threadsSpinnerStatus || $scope.converseSpinnerStatus;
            };


            $scope.toggleView = function (contact, threadID) {
                
                if (contact) {
                    $scope.threadsSpinnerStatus = true;
                    $scope.isThreadsConverseSpinnerActive = true;
                    $scope.isMessagesLoading = true;
                    $scope.setContactModel(contact, threadID);
                    $scope.showContent = false;

                } else {
                    if (!$scope.isMessagesLoading) {
                        $scope.contactModel = null;
                    }
                    
                }
                
                $('#contactListView').click('li', function () {
                    $('#contactListView').collapse('hide');
                });
            };

            $scope.tinymceModel = {};

            $scope.popover = {
                scopeHelper: $scope,
                options: {
                    html: true,
                    container: '#cancelButton',
                    content: '<div>Are you sure you want to cancel and discard this message?</div>' +
                                    '<div class="spacer10"></div>' +
                                '<div class="clearfix"><div class="pull-right"><button type="button" class="btn secondary" ng-click="closePopover($event)"><b>No</b></button>&nbsp;&nbsp;&nbsp;&nbsp;<button type="button" class="btn primary" ng-click="confirmCancel()">Yes</button></div></div>',
                    placement: 'top'
                },
            };

            $scope.closePopover = function (event) {
                $scope.popover.options.dismissPopover();
                event.stopPropagation();
            };

            var tinymce = $window.tinymce;
            $scope.tinymceOptions = {
                toolbar: "undo redo | bold italic | alignleft alignjustify | bullist numlist | link unlink",
                plugins: "link",
                target_list: false,
                link_title: false,
                extended_valid_elements: 'a[href|target=_blank]',
                relative_urls: false,
                menubar: false,
                statusbar: false,
                content_css: "/dist/css/tinymce.css",
                init_instance_callback: function (ed) {
                    ed.on('click', function (e) {
                        ed.focus();
                        $scope.setCharaterCount();
                    });

                    ed.on('KeyUp', function (editor, e) {
                        $scope.setCharaterCount();
                    });
                }
            };

            $scope.setCharaterCount = function () {
                var tinyContent = tinymce.activeEditor.getContent({ format: 'text' });
                $scope.characterCount = tinyContent.length === 1 && /\s/g.test(tinyContent) && tinyContent !== '' ? 0 : tinyContent.length;
                $timeout(function () {
                    $scope.$apply();
                });
            };

            tinymce.init($scope.tinymceOptions);
            function isMessagesSwitchedOff() {
                messagesApi.isMessagesSwitchedOff({}, function(data) {
                    $scope.isMessagesSwitchedOff = data.IsMessagesSwitchedOff;
                });
            }

            isMessagesSwitchedOff();
            $scope.loadThreadMessages = function (profileType, companyReference, threadId, participantsHash) {
                $scope.contactModel = {};
                var profiles;
                $scope.threadsSpinnerStatus = true;
                $scope.converseSpinnerStatus = true;

                profileApi.profiles({ profileType: '' }, function (data) {
                    profiles = data;
                    if (profiles) {
                        $rootScope.ownerTheme = $stateParams.profileType === 'owner' ? true : false;
                        for (var p = 0; p < profiles.length; p++) {
                            if (profiles[p].ProfileType === 'Owner') {
                                $scope.isOwner = true;
                            }

                            if (profiles[p].ProfileType === 'Tenancy') {
                                $scope.isTenant = true;
                            }
                        }

                        if (profileType === 'Owner') {
                            $scope.showOwner = true;
                            $scope.showTenant = false;
                            $scope.isOwnerTabActive = true;
                        }
                        else {
                            $scope.showOwner = false;
                            $scope.showTenant = true;
                            $scope.isOwnerTabActive = false;
                        }

                        messagesApi.getContacts({ contactType: profileType }, function success(data) {
                            var contacts = data;
                            if (contacts) {
                                if (threadId) {
                                    messagesApi.getThreadDetails({ companyReference: companyReference, threadID: threadId, contactType: profileType }, function success(data2) {
                                        var thread = data2;
                                        if (thread) {
                                            for (var j = 0; j < contacts.length; j++) {
                                                if (contacts[j].ParticipantsHash == thread.ParticipantsHash) {
                                                    $scope.toggleView(contacts[j], threadId);
                                                }
                                            }

                                            $scope.showContent = true;
                                            $intercom.trackEvent('read-message');
                                        }
                                    });
                                } else if (participantsHash) {
                                    for (var j = 0; j < contacts.length; j++) {
                                        if (contacts[j].ParticipantsHash == participantsHash) {
                                            $scope.toggleView(contacts[j], '');
                                        }
                                    }
                                }

                                $scope.showContent = true;
                            }
                        });
                    }
                }, function (response) {
                    $scope.threadsSpinnerStatus = false;
                    $scope.converseSpinnerStatus = false;
                });
            };

            $scope.canChangeTab = function () {
                if ($scope.threadsSpinnerStatus || $scope.converseSpinnerStatus) {
                    return false;
                }
                return true;
            };

            $scope.getUnreadCounts = function () {

                $scope.model.totalUnreadCount = 0;
                messagesApi.getUnreadMessageCount({ contactType: 'Owner' }, function success(data) {
                    if (data.UnreadCount !== 0) {
                        $scope.model.ownerUnreadCount = "(" + data.UnreadCount + ")";
                        $scope.model.totalUnreadCount += data.UnreadCount;
                    }
                    else {
                        $scope.model.ownerUnreadCount = "";
                    }
                });

                messagesApi.getUnreadMessageCount({ contactType: 'Tenancy' }, function success(data) {
                    if (data.UnreadCount !== 0) {
                        $scope.model.tenantUnreadCount = "(" + data.UnreadCount + ")";
                        $scope.model.totalUnreadCount += data.UnreadCount;
                    }
                    else {
                        $scope.model.tenantUnreadCount = "";
                    }
                });
            };

            $scope.$on('ReloadUnreadMessages', $scope.getUnreadCounts);

            $scope.resetUnreadMessages = function (thread) {
                for (var i = 0; i < $scope.contactModel.threads.length; i++) {
                    if ($scope.contactModel.threads[i].ThreadID == thread.ThreadID) {
                        $scope.contactModel.threads[i].HasUnreadMessages = false;
                    }
                }
            };

            /////////// Individual contact thread view functions

            $scope.setContactModel = function (contact, threadID) {
                $scope.contactModel = {
                    attachments: [],
                    selectedContact: contact
                };

                $scope.retrieveContactThreads(contact.CompanyReference, contact.ParticipantsHash, threadID);
            };

            $scope.retrieveContactThreads = function (companyReference, participants, threadID) {
                $scope.isDropdownActive = false;
                messagesApi.getThreads({ companyReference: companyReference, participants: participants }, function success(data) {
                    $scope.contactModel.threads = data;
                    $scope.contactModel.threads[0].HasUnreadMessages = false;
                    $scope.threadsSpinnerStatus = false;
                    if ($scope.contactModel.threads.length > 0) {
                        if (threadID) {
                            for (var i = 0; i < $scope.contactModel.threads.length; i++) {
                                if ($scope.contactModel.threads[i].ThreadID == threadID) {
                                    $scope.contactModel.selectedThread = $scope.contactModel.threads[i];
                                    $scope.contactModel.selectedThread.HasUnreadMessages = false;
                                    break;
                                }
                            }
                        } else {
                            $scope.contactModel.selectedThread = $scope.contactModel.threads[0];
                        }
                    }
                    
                    
                    $scope.isDropdownActive = true;
                    if (!$scope.showContent) {
                        $scope.status.isThreadsOpen = !$scope.status.isThreadsOpen;
                    }
                });
            };

            $scope.getAttachmentUrl = function (rawUrl) {
                return $sce.trustAsResourceUrl(rawUrl);
            };

            $scope.retrieveThreadMessages = function (companyReference, threadID) {
                $scope.converseSpinnerStatus = true;
                $scope.contactModel.messages = [];
                messagesApi.getMessages({ companyReference: companyReference, threadID: threadID, markAsRead: true }, function success(data) {
                    $scope.converseSpinnerStatus = false;
                    if ($scope.contactModel && $scope.contactModel.messages) {
                        $scope.contactModel.messages = data;
                        $scope.scrollToTop();
                        $interval(function () {
                            $scope.scrollToBottom();
                        }, 300, 2);
                    }

                    $scope.isMessagesLoading = false;
                });
            };

            $scope.scrollToTop = function () {
                $('#conversationBoxDesktop').scrollTop(0);
                $('#conversationBoxMobile').scrollTop(0);
            };

            $scope.scrollToBottom = function () {
                var $desktopDiv = $('#conversationBoxDesktop'),
                    $mobileDiv = $('#conversationBoxMobile');

                //Scrolls to the top of the last message in the thread
                $desktopDiv
                    .scrollTop($desktopDiv.find('div.coversation-row').last().prop('offsetTop'))
                    .perfectScrollbar('update');

                //Mobile seems to add the parents offset to all children - negate it to scroll to correct position
                $mobileDiv
                    .scrollTop($mobileDiv.find('div.coversation-row').last().prop('offsetTop') - $mobileDiv.prop('offsetTop')) 
                    .perfectScrollbar('update');
            };

            $scope.selectThread = function (thread) {
                $state.go('shell.messageThreadView', {
                    profileType: $stateParams.profileType,
                    companyReference: thread.OriginalFromAddress.CompanyReference,
                    threadId: thread.ThreadID
                });

                localThreadId = thread.ThreadID;
            };

            $scope.$watch('contactModel.selectedThread', function (val) {
                if ($scope.contactModel && $scope.contactModel.selectedThread) {
                    $scope.retrieveThreadMessages(val.OriginalFromAddress.CompanyReference, val.ThreadID);
                }

                $rootScope.$broadcast('ReloadUnreadMessages');
            });

            $scope.showConversationDivider = function ($index) {
                if (!$scope.contactModel || !$scope.contactModel.messages) return false;
                return $index < $scope.contactModel.messages.length - 1 && $scope.contactModel.messages[$index].IsUser && $scope.contactModel.messages[$index + 1].IsUser;
            };

            $scope.reply = function () {
                $scope.firstAttempt = true;

                if (!$scope.canReplyMessage()) {
                    flash.error('There were errors. Please correct these errors below.');
                    return;
                }

                if (!$scope.contactModel || $scope.contactModel.message === undefined || $scope.contactModel.message === null || !$scope.contactModel.message.length) return;

                $q.all($scope.contactModel.attachments.map(function (item) { return item.readAsync(); })).then(function (vals) {
                    var param = {
                        CompanyReference: $scope.contactModel.selectedThread.OriginalFromAddress.CompanyReference,
                        ThreadID: $scope.contactModel.selectedThread.ThreadID,
                        Message: $scope.contactModel.message,
                        Attachments: vals.map(function (a) {
                            return {
                                Name: a.name,
                                MimeType: a.type,
                                Data: a.data.substring(a.data.indexOf(',') + 1)
                            };
                        })
                    };
                    $scope.isSending = true;
                    $scope.doBlur = true;
                    messagesApi.reply(param, function success(val) {
                        $window.Intercom('trackEvent', 'reply-message');
                        $scope.retrieveThreadMessages($scope.contactModel.selectedThread.OriginalFromAddress.CompanyReference, $scope.contactModel.selectedThread.ThreadID);
                        $scope.contactModel.message = '';
                        $scope.contactModel.attachments = [];
                        $scope.firstAttempt = null;
                        $scope.hasBodyError = false;
                        $scope.hasSubjectError = false;
                        $scope.isSending = false;
                        $scope.doBlur = false;
                        $scope.characterCount = 0;
                        flash.success('Message Successfully Sent');
                    }, function (error) {
                        $scope.isSending = false;
                        flash.error('Sorry, there was a problem sending your message.  Please try again.');
                    });
                });
            };

            $scope.addReplyAttachment = function (file) {
                if (!$scope.contactModel || !$scope.contactModel.attachments) return;

                var param = {
                    Name: file.name,
                    MimeType: file.type,
                    Size: file.size
                };

                messagesApi.validateAttachment(param, function success(val) {
                    $scope.contactModel.attachments.push(file);
                }, function (error) {
                    if (error.status === 400) {
                        flash.error(error.data);
                    }
                });
            };

            $scope.removeReplyAttachment = function (index) {
                if (!$scope.contactModel || !$scope.contactModel.attachments) return;
                $scope.contactModel.attachments.splice(index, 1);
            };

            /////////// Compose new message functions.
            $scope.composeMessage = function () {
                if ($stateParams.profileType === 'owner') {
                    $state.go('shell.messageCompose', {
                        profileType: 'owner'
                    });
                } else {
                    $state.go('shell.messageCompose', {
                        profileType: 'tenant'
                    });
                }
            };

            $scope.gotoInbox = function() {
                if ($stateParams.profileType === 'owner') {
                    $state.go('shell.messages', {
                        profileType: 'owner'
                    });
                } else {
                    $state.go('shell.messages', {
                        profileType: 'tenant'
                    });
                }
            };

            $scope.$watch('contactModel.message', function (val) {
                $scope.canReplyMessage();
            });
            
            $scope.canReplyMessage = function () {
                if ($scope.firstAttempt && $scope.contactModel) {
                    if ($scope.contactModel.message) {
                        $scope.hasBodyError = checkContentEmpty($scope.contactModel.message) === 0;
                    }
                    else {
                        $scope.hasBodyError = true;
                    }
                    if ($scope.hasBodyError || $scope.hasExceededFileSize) {
                        return false;
                    }
                }
                return true;
            };

            function checkContentEmpty(message) {
                var local = message;
                var replaced = local.replace(/&nbsp;|\s|<p>|<\/p>/gm, '');
                return replaced.length;
            }

            $scope.loadThreadMessages($stateParams.profileType === 'owner' ? 'Owner' : 'Tenancy', $stateParams.companyReference, $stateParams.threadId, $stateParams.participantsHash);

            function checkFormDirty() {
                if (tinymce.activeEditor) {
                    var text = tinymce.activeEditor.getContent({ format: 'text' });
                    $scope.isContentEmpty = !/\S/.test(text);
                    return !!(!$scope.isContentEmpty || ($scope.contactModel.attachments && $scope.contactModel.attachments.length > 0));
                } else {
                    return false;
                }
            }

            var onRouteChangeOff = $rootScope.$on('$stateChangeStart', routeChange);

            function routeChange(event, next, current) {
                var isFormDirty = checkFormDirty();
                if (isFormDirty) {
                    var buttonDefinition = [{ result: 'cancel', label: 'Cancel' }, { result: 'ok', label: 'Discard Message', cssClass: 'primary' }];
                    var message = '<p>Your message has not been sent.<br/>Are you sure you want to leave this page and discard your changes?<p>';
                    shell.confirmBox('Confirm Cancel Message', message, buttonDefinition, true)
                    .result.then(function (result) {
                        if (result == "ok") {
                            onRouteChangeOff();
                            if (next.name == "shell.messageThreadView") {
                                $state.go(next.name, { profileType: $stateParams.profileType, threadId: localThreadId });
                            } else if (next.name === 'shell.manageInspections') {
                                $state.go(next.name, { profileType: 'tenant', inspectionId: $rootScope.inspectionId });
                            } else {
                                $state.go(next.name, { profileType: $stateParams.profileType });
                            }
                        }
                    });
                    event.preventDefault();
                    return;
                } else {
                    onRouteChangeOff();
                }
            }
        }]);
})();
