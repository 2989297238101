'use strict';

/* Controllers */
(function () {
    angular.module('pt.controllers')
        .controller('reportsStatementsCtrl', ['$scope', '$rootScope', '$http', 'resourceFactory', '$window', 
            '$state', 
            '$intercom',
            '$stateParams',
            function ($scope, $rootScope, $http, resourceFactory, $window, $state, $intercom, $stateParams) {

            var profileApi = resourceFactory.create('profile'),
                ownerstatementsApi = resourceFactory.create('ownerstatements'),
                incomeExpensesApi = resourceFactory.create('incomeexpenditurestatements');

            $scope.ownerStatementsSpinner = true;
            $scope.showStatements = false;
            $scope.showIncome = false;

            $rootScope.ownerTheme = true; // styling the theme of the page based on role

            function getOwners() {
                $scope.selectedOwner = null;
                profileApi.profiles({ profileType: 'Owner' }, function (data) {
                    $scope.owners = data;
                    if ($scope.owners.length > 0) {
                        $scope.selectedOwner = $scope.owners[0];
                    }
                });
            }

            function getOwnerStatements(owner) {
                ownerstatementsApi.query({ CompanyReference: owner.CompanyReference, ContactReference: owner.ContactReference, OwnerReference: owner.ProfileReference }, function (data) {
                    $scope.statements = data;
                    $scope.displayStatements = [].concat($scope.statements);
                    insertPopoverOptions();
                    $scope.ownerStatementsSpinner = false;
                }, function(error) {
                    $scope.ownerStatementsSpinner = false;
                });
            }

            function insertPopoverOptions() {
                _($scope.displayStatements).forEach(function (statement) {
                    var index = _.indexOf($scope.displayStatements, statement);
                    statement.popover = {
                        scopeHelper: $scope,
                        options: {
                            html: true,
                            trigger: 'hover',
                            container: '#attachments_' + statement.StatementReference,
                            content: '<div><div ng-repeat="attachment in displayStatements[' + index + '].CreditorAttachments"><a ng-href="{{attachment.Url}}" target="_blank" class="link-black">{{attachment.FileName}}</a></div></div>',
                            placement: 'top'
                        },
                    };
                });
            }

            function getIncomeExpenses(owner) {
                incomeExpensesApi.query({ CompanyReference: owner.CompanyReference, ContactReference: owner.ContactReference, OwnerReference: owner.ProfileReference }, function (data) {
                    $scope.incomeExpenses = data;
                    $scope.displayIncomeExpenses = [].concat($scope.incomeExpenses);
                    $scope.incomeExpensesSpinner = false;
                }, function (error) {
                    $scope.incomeExpensesSpinner = false;
                });
            }

            $scope.$watch('selectedOwner', function (val) {
                if (val) {
                    if ($stateParams.reportType) {
                        if ($stateParams.reportType === 'incomeExpense') {
                            $scope.incomeExpenditureTabSelected();
                        } else {
                            $scope.ownerStatementsTabSelected();
                        }
                    } else {
                        if ($scope.showStatements) {
                            $scope.ownerStatementsSpinner = true;
                            getOwnerStatements(val);
                        }

                        if ($scope.showIncome) {
                            $scope.incomeExpensesSpinner = true;
                            getIncomeExpenses(val);
                        }
                    }
                }
            });

            $scope.profileSelected = function (val) {
                if (val) {

                    $scope.selectedOwner = val;
                }
            };

            $scope.statementUrl = function (statement) {
                if (statement) {
                    return PTConfig.APP_API_BASE_URL +
                        "/Statements/OwnerStatement/" + $scope.selectedOwner.CompanyReference +
                        "/" + $scope.selectedOwner.ContactReference +
                        "/" + statement.StatementReference;
                }

                return "";
            };

            $scope.openOwnershipStatement = function(){
                $window.Intercom("trackEvent", "view-ownership-statement");
            };

            $scope.incomeExpensesUrl = function (statement) {
                if (statement) {
                    return PTConfig.APP_API_BASE_URL +
                        "/Statements/IncomeExpenditureStatement/" + $scope.selectedOwner.CompanyReference +
                        "/" + $scope.selectedOwner.ContactReference +
                        "/" + statement.StatementReference;
                }

                return "";
            };
            $scope.openIandE = function(){
                $window.Intercom("trackEvent", "view-i&e-statement");
            };

            getOwners();

            $scope.ownerStatementsTabSelected = function () {
                $scope.ownerStatementsSpinner = true;
                getOwnerStatements($scope.selectedOwner);
                $scope.showStatements = true;
                $scope.showIncome = false;
            };

            $scope.incomeExpenditureTabSelected = function () {
                $scope.incomeExpensesSpinner = true;
                getIncomeExpenses($scope.selectedOwner);
                $scope.showIncome = true;
                $scope.showStatements = false;
            };

        }]);
})();
