(function() {
    'use strict';

    angular.module('pt.filters')
        .filter('tdPercentage', filterFn);

    filterFn.$inject = ['$filter'];
    function filterFn($filter) {
        return function (input, decimals) {
            return $filter('number')(input * 100, decimals) + '%';
        };
    }

})();
