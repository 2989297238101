// Angular Directives to handle tinymce blur event
// Main Issue: blur event not detected in iPad / iPhone 
// so it will not reomove the onscreen keyboard 
// this directive will help to solve this
"use strict";
(function(){
  angular.module('pt.directives')
    .directive("tdInputBlur", function(){
      var dir = {
        template:'<input disabled="disabled" style="width:0; height:0; opacity:0" type="text" />',
        replace:true,
        require:{
          doBlur:"^"
        },
        scope:{
          doBlur:"="
        },
        link:function($scope, el, attrs){
          var list = $scope.$watch('doBlur', function(newval){
            $(el).removeAttr('disabled');
            if(newval) {
              $(el).focus().blur()
                .attr({disabled:'disabled'});
            }
          });
          
          $scope.$on('destroy', function(){
            list();
          });

        }
      };
      return dir;
    });
})();
