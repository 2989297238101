'use strict';

(function () {
    angular.module('pt.controllers')
        .controller('maintenanceRequestsCtrl', ['$scope', '$rootScope', '$state', '$stateParams', 'resourceFactory', 'shell',
            function controllerFn($scope, $rootScope, $state, $stateParams, resourceFactory, shell) {
                var tenancyAPI = resourceFactory.create('tenancies');
                var ownerAPI = resourceFactory.create('owners');

                $scope.profileType = $stateParams.profileType;
                var isTenancy = /tenan/gi.test($scope.profileType);
                $scope.isTenancy = isTenancy;
                $rootScope.isTenancy = isTenancy ? true : false;
                $scope.profiles = [];

                $scope.vm = {
                    profiles:[],
                    selectedProfile: undefined,
                    maintenanceRequests:[],
                    MROpen:[],
                    MRCompleted:[],
                    MRCancelled:[],
                    mrReqSts: false,
                };

                function getCoreMaintenaceFeature() {

                    $rootScope.companyFeatures.forEach(setCoreMaintenaceFeature);

                    function setCoreMaintenaceFeature(companyFeatures, index) {
                        if (companyFeatures.OptionalFeature.OptionalFeatureType === "CoreMaintenance") {
                            $scope.vm.isCoreMaintenanceVisible = true;
                        }
                        else {
                            $scope.vm.isCoreMaintenanceVisible = false;
                        }
                    }
                }
                getCoreMaintenaceFeature();
                $scope.profileSelected = profileSelectedFn;

                $scope.vm.profiles = _.filter($rootScope.user.Profiles, function (pr) {
                    if(isTenancy)
                        return /tenancy/gi.test(pr.ProfileType);
                    else
                        return /management/gi.test(pr.ProfileType);
                });

                if($scope.vm.profiles.length >0)
                    $scope.vm.selectedProfile = $scope.vm.profiles[0];

                function init(){
                    var reqAPI;
                    var reqObj = {
                        CompanyReference:$scope.vm.selectedProfile.CompanyReference,
                        ContactReference:$scope.vm.selectedProfile.ContactReference
                    };

                    if (isTenancy){
                        reqAPI = tenancyAPI;
                        reqObj.TenancyReference = $scope.vm.selectedProfile.ProfileReference;
                    } else {
                        reqAPI = ownerAPI;
                        reqObj.OwnerReference = $scope.vm.selectedProfile.OwnershipReference;
                        reqObj.propertyReference = $scope.vm.selectedProfile.PropertyReference;
                    }

                    var mrreq = reqAPI.maintenanceRequests(reqObj).$promise;

                    $scope.vm.mrReqSts = true;
                    $scope.vm.MROpen = [];
                    $scope.vm.MRCompleted = [];
                    $scope.vm.MRCancelled = [];
                    mrreq.then(function(data){
                        $scope.vm.maintenanceRequests = data;
                        $scope.vm.approvalList = _.filter(data, function(req) {
                            return req.HasApprovalPending;
                        });

                        _.each(data, function(mr){
                            switch(mr.DisplayStatus.toLowerCase()) {
                                case 'open': $scope.vm.MROpen.push(mr); break;
                                case 'completed': $scope.vm.MRCompleted.push(mr); break;
                                case 'cancelled': $scope.vm.MRCancelled.push(mr); break;
                            }
                        });
                    }).finally(function(err){
                        $scope.vm.mrReqSts = false;
                    });
                }

                if( $scope.vm.selectedProfile ){
                    init();
                }

                function profileSelectedFn(profile){
                    $scope.vm.selectedProfile = profile;
                    init();
                }

                // Maintenance provider information dialog ----------------------------

                var providerInfo = {
                    templateUrl: '/partials/maintenanceRequests/maintenanceProviderInfoDialog.html',
                    controller: 'maintenanceProviderInfoCtrl',
                    windowClass: 'animated ',
                    size: 'md',
                    resolve: {
                        items: function () {
                            return $scope.items;
                        },
                        type: function () {
                            return "";
                        }
                    }
                };

                $scope.showMaintenanceProviderInfo = function () {
                    var dlg = shell.createDialog(providerInfo);

                    dlg.result.then(function (val) {
                    });
                };

                // ----------------------------------------------
            }])
        .controller('maintenanceProviderInfoCtrl', ['$scope', '$uibModalInstance', 'items', 'type', 'resourceFactory', function ($scope, $uibModalInstance, items, type, resourceFactory) {
            $scope.cancel = function () {
                $uibModalInstance.close();
            };
        }]);
})();


