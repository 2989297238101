"use strict";
angular.module('pt.directives')
.directive('tdScrollTo', function(){
  var dir = {
    scope:{
      trigger:"@"
    },
    link:function($scope, el, attrs){

      var trglstn = $scope.$watch('trigger', function(val){

        if(val && val === 'true'){
          var top = $(el).position().top;
          $(el).parent().parent().scrollTop(top);
          $scope.trigger = false;
        }
      });

      $scope.$on('destroy', function(){
        trglstn();
      });

    }
  };
  return dir;
});