'use strict';

/* Controllers */
(function () {
    angular.module('pt.controllers')
      .controller('manageInspectionsCtrl', ['$rootScope', '$scope', '$sce', '$http', 'flash', 'resourceFactory', '$stateParams', '$state', '$q', '$interval', 'usSpinnerService', '$window', '$timeout', 'shell', '$location', function ($rootScope, $scope, $sce, $http, flash, resourceFactory, $stateParams, $state, $q, $interval, usSpinnerService, $window, $timeout, shell, $location) {
        var profileApi = resourceFactory.create('profile'),
            inspectionapi = resourceFactory.create('inspections'),
            messagesApi = resourceFactory.create('messages');


          $scope.showInspectionWidget = false;
          $scope.username = $rootScope.user.Name;
          $scope.showProposeLink = false;
          $scope.hasReasonError = false;
          $rootScope.ownerTheme = false;
          $scope.showPostProposal = false;
          $scope.myTime = new Date();
          $scope.model = {};
          $scope.loading = true;
          $scope.model.reason = '';
          $scope.hasTimeError = false;
          $scope.hasDateError = false;
          $scope.showConfirmButton = false;
          
          $scope.proposeNewTime = function () {
            $scope.showConfirmButton = false;
            $timeout(function () {
                $scope.showProposeLink = false;
                $scope.showInspectionWidget = true;
            }, 500);
            
            $scope.showPostProposal = false;
          };

          function isMessagesSwitchedOff() {
              messagesApi.isMessagesSwitchedOff({}, function (data) {
                  $scope.isMessagesSwitchedOff = data.IsMessagesSwitchedOff;
              });
          }

          isMessagesSwitchedOff();

          $scope.popover = {
              scopeHelper: $scope,
              options: {
                  html: true,
                  container: '#cancelButton',
                  content: '<div>Are you sure you want to cancel your new inspection proposal?</div>' +
                                  '<div class="spacer10"></div>' +
                              '<div class="clearfix"><div class="pull-right"><button type="button" class="btn secondary" ng-click="closePopover($event, \'propose\')"><b>No</b></button>&nbsp;&nbsp;&nbsp;&nbsp;<button type="button" class="btn primary" ng-click="confirmCancel()">Yes</button></div></div>',
                  placement: 'top'
              }
          };

          $scope.confirmPopover = {
              scopeHelper: $scope,
              options: {
                  html: true,
                  container: '#confirmTime',
                  content: '<div style="font-weight:normal"><p>This time will be locked in and no <br/>' +
                                'further time or date changes can be made.<p></div>' +
                                  '<div class="spacer10"></div>' +
                              '<div class="clearfix"><div class="pull-right"><button type="button" class="btn secondary" ng-click="closePopover($event, \'confirm\')"><b>Cancel</b></button>&nbsp;&nbsp;&nbsp;&nbsp;<button type="button" class="btn primary" ng-click="saveConfirm()">Confirm</button></div></div>',
                  placement: 'top'
              }
          };

          $scope.closePopover = function (event, type) {
              if (type === 'propose') {
                  $scope.popover.options.dismissPopover();
                  event.stopPropagation();
              } else {
                  $scope.confirmPopover.options.dismissPopover();
                  event.stopPropagation();
              }
          };

          $scope.confirmCancel = function () {
              $scope.showInspectionWidget = false;
              $scope.model.reason = '';
              if ($scope.model.inspectionDetails.ProposalStatus === 'TenantProposed') {
                  $scope.setPostProposalDetails();
                  $scope.loadInspection($scope.tenancy, $stateParams.inspectionId);
              } else {
                  $scope.setInspection();
              } 
          };

          $scope.$watch('model.selectedDate', function (val) {
              if (val) {
                  $scope.hasDateError = false;
              } 
          });

          $scope.$watch('model.selectedTime', function (val) {
              if (val) {
                  $scope.hasTimeError = false;
              }
          });

          $scope.$watch('model.reason', function (val) {
              if (val) {
                  $scope.hasReasonError = false;
              }
          });

          $scope.setPostProposalDetails = function () {
            $scope.showPostProposal = true;
            $scope.showInspectionWidget = false;
            $scope.showProposeLink = true;
            $scope.showConfirmButton = false;
            $scope.model.headerText = "ORIGINAL PROPOSED TIME";
            $scope.displayClientTime = $scope.getClientTimeString($scope.model.selectedTime);
          };

          $scope.savePropose = function () {
              var params = {
                  CompanyReference: $scope.tenancy.CompanyReference,
                  ContactReference: $scope.tenancy.ContactReference,
                  InspectionId: $scope.model.inspectionDetails.InspectionID,
                  InspectionDate: $scope.model.selectedDate,
                  InspectionTime: $scope.model.selectedTime,
                  Reason: $scope.model.reason
              };

              $scope.hasDateError = !$scope.model.selectedDate ? true : false;
              $scope.hasTimeError = !$scope.model.selectedTime ? true : false;
              $scope.hasReasonError = !$scope.model.reason ? true : false;
              if ($scope.hasDateError || $scope.hasTimeError || $scope.hasReasonError) {
                  return;
              } else {
                  $scope.loading = true;
                  inspectionapi.proposeNewTime(params, function () {
                      flash.success('Inspection time updated');
                      $scope.setPostProposalDetails();
                      $scope.loadInspection($scope.tenancy, $stateParams.inspectionId);
                      $scope.loading = false;
                  });
              }
          };

          $scope.saveConfirm = function () {
              var params = {
                  CompanyReference: $scope.tenancy.CompanyReference,
                  ContactReference: $scope.tenancy.ContactReference,
                  InspectionId: $scope.model.inspectionDetails.InspectionID
              };

              $scope.loading = true;
              inspectionapi.updateStatus(params, function () {
                  $scope.model.inspectionDetails.Status = 'Confirmed';
                  $scope.showProposeLink = false;
                  $scope.showConfirmButton = false;
                  flash.success('Inspection confirmed');
                  $scope.loading = false;
              });
          };

          function checkFormDirty() {
              return $scope.showInspectionWidget && $scope.model && $scope.model.inspectionDetails && ($scope.model.reason || $scope.model.selectedDate || $scope.model.selectedTime);
          }

          var onRouteChangeOff = $scope.$on('$stateChangeStart', routeChange);

          function routeChange(event, next, current) {
              if (checkFormDirty()) {
                  var buttonDefinition = [{ result: 'cancel', label: 'Cancel' }, { result: 'ok', label: 'Discard New Inspection', cssClass: 'primary' }];
                  var message = '<p>Your new inspection time and date have not yet been sent.<br/>Are you sure you want to leave this page and discard your changes?<p>';
                  shell.confirmBox('Confirm Cancel New Proposal', message, buttonDefinition, true)
                  .result.then(function (result) {
                      if (result == "ok") {
                          onRouteChangeOff();
                          $state.go(next.name, { profileType: $stateParams.profileType });
                      }
                  });

                  event.preventDefault();
                  return;
              }
          }

          $scope.sendMessage = function() {
              $state.go('shell.messageThreadView', {
                  profileType: 'tenant',
                  threadId: $scope.model.inspectionDetails.ThreadID
              });
          };

          $scope.getClientTimeString = function (inspectionTime) {
              if (inspectionTime) {
                  var time = moment(inspectionTime);
                  var starTime = time.format("h:mm");
                  var startAmPm = time.format("A") === 'AM' ? 'a.m' : 'p.m';

                  var endTime = time.add($scope.model.inspectionDetails.Duration, 'minutes').format("h:mm");
                  var endAmPm = time.format("A") === 'AM' ? 'a.m' : 'p.m';
                  return starTime + ' ' + startAmPm + ' - ' + endTime + ' ' + endAmPm;
              }

              return '??:??';
          };

            $scope.loadTenancyProfile = function() {
                profileApi.profiles({ profileType: 'Tenancy' }, function (data) {
                    $scope.tenancy = data[0];
                    $scope.loadInspection($scope.tenancy, $stateParams.inspectionId);
                }, function (error) {
                    $scope.loading = false;
                });
            };

            $scope.loadInspection = function (tenancy, inspectionId) {
                if (inspectionId) {
                    var params = {
                        CompanyReference: tenancy.CompanyReference,
                        ContactReference: tenancy.ContactReference,
                        InspectionId: inspectionId
                    };

                    inspectionapi.getInspection(params, function success(data) {
                        $scope.loading = false;
                        if (data.InspectionID) {
                            $scope.model.inspectionDetails = data;
                            $scope.model.currentStatusDisplay = data.Status === 'Closed' ? 'Conducted' : data.Status;
                        } else {
                            $scope.model.inspectionDetails = '';
                        }
                        
                        $scope.setInspection();

                    }, function(error) {
                        $scope.loading = false;
                    });
                } else {
                    $scope.loading = false;
                    return;
                }
            };

            $scope.loadTenancyProfile();
            $scope.statusList = [{ color: 'blue', status: 'proposed', isEmpty: false, isLastItem: false },
                            { color: 'orange', status: 'confirmed', isEmpty: true, isLastItem: false },
                            { color: 'green', status: 'conducted', isEmpty: true, isLastItem: true }];


            $scope.calculateDuration = function(duration) {
                var result = '';
                if (duration && duration > 59) {
                    if (duration == 60) {
                        result = '1 hour';
                    } else {
                        var totalDuration = duration / 60;
                        var hrs = Math.floor(totalDuration);
                        var mins = totalDuration > hrs ? Math.round((totalDuration - hrs) * 60) : 0;
                        var hrsText = hrs > 1 ? ' hours' : ' hour';
                        var minsText = mins > 1 ? ' minutes' : ' minute';
                        result = mins > 0 ? hrs + hrsText + ' ' + mins + minsText : hrs + hrsText;
                    }
                } else {
                    result = duration + ' minutes';
                }
                return result;
            };

            $scope.setInspection = function () {
                $scope.durationText = $scope.calculateDuration($scope.model.inspectionDetails.Duration);
                $scope.displayAgentTime = $scope.getClientTimeString($scope.model.inspectionDetails.InspectionTime);
                $scope.model.selectedDate = $scope.model.inspectionDetails.ProposalStatus && $scope.model.inspectionDetails.ProposalStatus === 'TenantProposed' && $scope.model.inspectionDetails.ClientInspectionDate ? $scope.model.inspectionDetails.ClientInspectionDate : $scope.model.inspectionDetails.InspectionDate;
                $scope.model.selectedTime = $scope.model.inspectionDetails.ProposalStatus && $scope.model.inspectionDetails.ProposalStatus === 'TenantProposed' && $scope.model.inspectionDetails.ClientInspectionTime ? $scope.model.inspectionDetails.ClientInspectionTime : $scope.model.inspectionDetails.InspectionTime;

                if ($scope.model && $scope.model.inspectionDetails) {
                    if ($scope.model.inspectionDetails.ProposalStatus === 'TenantProposed') {
                        $scope.setPostProposalDetails();
                    } else {
                        switch ($scope.model.inspectionDetails.Status) {
                            case 'Proposed':
                                $scope.model.headerText = 'Proposed Time';
                                $scope.showProposeLink = true;
                                $scope.showConfirmButton = true;
                                break;
                            case 'Confirmed':
                                $scope.model.headerText = 'Confirmed Inspection';
                                $scope.showPostProposal = false;
                                $scope.showConfirmButton = false;
                                break;
                            case 'Conducted':
                            case 'Closed':
                                $scope.model.headerText = 'Inspection Conducted';
                                break;
                        }
                    }
                }
            };

    }]).animation('.slide', function () {
          var NG_HIDE_CLASS = 'ng-hide';
        return {
            beforeAddClass: function(element, className, done) {
                if (className === NG_HIDE_CLASS) {
                    element.slideUp(done);
                }
            },
            removeClass: function(element, className, done) {
                if (className === NG_HIDE_CLASS) {
                    element.hide().slideDown(done);
                }
            }
        };
    });
})();
