/* Setup the resource registrations for this module */

"use strict";

angular.module('pt.controllers').run([
        'resourceFactory',
        function (resourceFactory) {
            resourceFactory
                .register('tenancies',
                    '/tenancies/:CompanyReference/:ContactReference/:TenancyReference/:action',
                    { CompanyReference: '@CompanyReference', ContactReference: '@ContactReference', TenancyReference: '@TenancyReference' }, {
                        receipts: { method: 'GET', isArray: true, params: { action: 'Receipts' } },
                        ledgerReport: { method: 'GET', params: { action: 'LedgerReport' } },
                        invoices: { method: 'GET', isArray: true, params: { action: 'Invoices' } },
                        list: { method:'GET', isArray:true },
                        maintenanceRequests: { method:'GET', isArray:true, params: { action: 'maintenanceRequests' } }
                    })
                .register('inspections',
                    '/inspections/:CompanyReference/:ContactReference/:InspectionId/:action',
                    { CompanyReference: '@CompanyReference', ContactReference: '@ContactReference', InspectionId: '@InspectionId' }, {
                        getInspection: { method: 'GET', params: { } },
                        proposeNewTime: { method: 'POST', params: { action: 'Propose'} },
                        updateStatus: { method: 'POST', params: { action: 'Confirm'} },
                    })
                .register('inspection',
                    '/inspections/:CompanyReference/:ContactReference/:ProfileReference/:action',
                    { CompanyReference: '@CompanyReference', ContactReference: '@ContactReference', ProfileReference: '@ProfileReference' }, {
                        getInspectionByEntityID: { method: 'GET', isArray: true, params: { action: 'Inspection'} },
                    });
        }
]);
