'use strict';
angular.module('pt.services')
  .factory('flash', [
        '$rootScope',
        function ($rootScope) {            
            return {
                success: function (message) {
                    var flash = { message: message, level: 'success' };                    
                    $rootScope.$broadcast('flash', flash);
                },

                info: function (message) {
                    var flash = { message: message, level: 'info' };
                    $rootScope.$broadcast('flash', flash);
                },

                error: function (message) {
                    var flash = { message: message, level: 'error' };
                    $rootScope.$broadcast('flash', flash);
                }              
            };
        }
  ]);